const ReportEndpoints = {
    // -----------METHOD IS POST---------
      clientSOW(reqData: any) {
        return {
          url: "reports/client-sow",
          data: reqData["reqBody"],
          headers: reqData["headers"],
        };
      },
      pendingPayment(reqData: any) {
        return {
          url: "reports/pending-payments",
          data: reqData["reqBody"],
          headers: reqData["headers"],
        };
      },
      reports(reqData :any) {
        return {
          url: "reports/customer-invoice",
          data:reqData['reqBody'],
          headers:reqData['headers'],
        }
      },
      departmentReports(reqData :any) {
        return {
          url: "reports/department-report",
          data:reqData['reqBody'],
          headers:reqData['headers'],
        }
      },
      cumulativeReports(reqData :any) {
        return {
          url: "reports/monthly-report",
          data:reqData['reqBody'],
          headers:reqData['headers'],
        }
      }
    };

export default ReportEndpoints;