const Statementofworkendpoints = {
  sow(reqData: any) {
    return {
      url: "sow/sow",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  sow_project(reqData: any) {
    return {
      url: "sow/project",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  sow_customer(reqData: any) {
    return {
      url: "sow/customer",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  sow_department(reqData: any) {
    return {
      url: "sow/department",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  users(reqData: any) {
    return {
      url: "auth/user",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  uploadFile: (data: object) =>
  ({
    url: "sow/file/upload",
    data,
  }),
  sow_salesperson(reqData: any) {
    return {
      url: "sow/salePerson",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  invoice(reqData: any) {
    return {
      url: "invoices/invoice",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  milestone(reqData: any) {
    return {
      url: "sow/milestone",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  salesperson(reqData: any)
  {
    return {
      url: "sow/salesProject",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  department(reqData: any)
  {
    return {
      url: "sow/sowDept",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  }
};
export default Statementofworkendpoints;