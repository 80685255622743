import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { useImperativeHandle, useState, forwardRef } from 'react';
import Input from '../../../components/Input/Input';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DropDownComponent from '../../../components/Select/Select';
import useApiService from '../../../services/api.service';
import useLocalStorage from '../../../hooks/localStorage';
import Loader from '../../../components/Loader/Loader';
import { showToast } from '../../../components/Toast/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Errormessage from '../../../components/Errormessage/Errormessage';
import DepartmentEndpoints from '../../Departments/Deprtment.endpoints';
// import DepartmentDialogEndpoints from './Department.endpoints';
import Btn from '../../../components/Button/Button';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "80px 40px 40px 260px" },
    dialogcontainer: { padding: '15px',width:'600px'},

}
const Departmentdialog: React.FC<any> = forwardRef((props: any, ref: any) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const ApiService = useApiService();
    const loginData = useLocalStorage.getItem("loginData");

    useImperativeHandle(ref, () =>
    ({
        handleClickDepartmentDialogOpen: () => {
            setOpen(true);
        },
        handleClickDepartmentDialogClose: () => {
            setOpen(false);
        }
    }));


    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('This field is required'),
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset
    } = useForm
            ({
                resolver: yupResolver(validationSchema)
            });


    const submitProject = async (data: any) => {
        setLoading(true);

        console.log("-----------FORM SUBMITTED----------")
        console.log(data);

        const reqBody =
        {
            name:data.name
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header 
        };
        await ApiService.post(DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                showToast('SUCCESS', res.message);
                props.updateDepartmentList(({department_id: res.data.department_id, name: data.name}));
                resetFields();
                setOpen(false);
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            })

        setLoading(false);
    }


    const resetFields = () => {
        reset
            ({
               name:''
            })
    }

    return <React.Fragment>
        <Loader loading={loading}>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={styles.dialogcontainer}>
                    <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "0px" }}>
                        <p className="dialog_title">
                            Add Department
                        </p>
                    </DialogTitle>

                    <form >
                        <DialogContent >
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Input
                                            // required={true}
                                            type="text"
                                            label="Department Name*"
                                            register={register('name')}
                                            autoFocus={true}
                                            error={errors.name ? true : false}
                                        />
                                        <Errormessage errormessage={errors.name ? errors.name.message : ''} />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <Btn
                                variant="outlined"
                                onClick={() => {
                                    resetFields();
                                    setOpen(false);
                                } } btnText={'Cancel'} type={'button'}/>
                             
                            <Btn type="button" variant="contained" onClick={handleSubmit(submitProject)} btnText={'Save'}/>
                                
                        </DialogActions>
                    </form>
                </div>

            </Dialog>
        </Loader>
    </React.Fragment>
})

export default Departmentdialog;