import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

import styled from 'styled-components'

const StyledLoader = styled(LoadingOverlay)`
  width:100%;
`

export default function MyLoader({ loading, children }: any) {

  return (<

    >
    <StyledLoader
      styles={{
        // wrapper: (base) => ({
        //   ...base,
        //   overflow: loading ? 'hidden'  : ''
        // }),
        overlay: (base) => ({
          ...base,
          background: 'transparent',
          zIndex: 999,
        }),
        spinner: (base) => ({
          ...base,
          width: '60px',
          // position:"absolute",
          
          '& svg circle': {
            stroke: '#1976d2',
            screenTop:20,

            // position:"relative",
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',

          }
        })
      }}
      classNamePrefix='MyLoader_'
      active={loading}
      
      spinner={true}
    >

      {children}

    </StyledLoader>
  </>
  )
}