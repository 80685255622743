import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { useImperativeHandle, useState, forwardRef, Suspense } from 'react';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DropDownComponent from '../../components/Select/Select';
import Errormessage from '../../components/Errormessage/Errormessage';
import { showToast } from '../../components/Toast/Toast';
import useLocalStorage from '../../hooks/localStorage';
import useApiService from '../../services/api.service';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';
import { useRef, useEffect } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import AlertDialog from '../../components/Alertdialog/Dialog';
import ProjectsEndpoints from './Projects.endpoints';
import { cpuUsage } from 'process';
import Btn from '../../components/Button/Button';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", 
    margin: "0px 0px 0px 230px",
    backgroundColor:"white" },
    dialogcontainer: { padding: '15px', width: '600px' },

}

const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}


const dummyPaymentData =
    [
        { name: 'Monthly Retainer' },
        { name: 'Adhoc Basis' },
        { name: 'Fixed Cost' },
    ]


const Projects = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const ApiService = useApiService();
    const loginData = useLocalStorage.getItem("loginData");
    const [operation, setOperation] = useState('');
    const deleteTaskRef: any = useRef();
    const [clickedRow, setClickedRow]: any = useState({});
    const [projects, setProjects]: any = useState([]);
    const [customers, setCustomers]: any = useState([]);
    const [submission, setSubmission] = useState(false)

    const getProjects = async () => {
        setLoading(true);

        const reqBody: any = {};

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(ProjectsEndpoints.project(reqData))
            .then((res: any) => {
                setProjects(res.data);
            }).finally(() => {
                setLoading(false);
            });
    }


    const getCustomers = async () => {
        setLoading(true);

        const reqBody: any = {};

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(ProjectsEndpoints.customer(reqData))
            .then((res: any) => {
                setCustomers(res.data);
            }).finally(() => {
                setLoading(false);
            });
    }


    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        project_name: Yup.string()
            .trim()
            .required('This field is required'),
        project_type:
            Yup.string().required('This field is required'),
        customer_id: Yup.string().required('This field is required')
    });


    
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset
    } = useForm
            ({
                resolver: yupResolver(validationSchema)
            });

    useEffect(() => {
        getProjects();
        getCustomers();
    }, [])


    const submit = async (data: any) => {
        // setLoading(true);
setSubmission(true)
        console.log("-----------FORM SUBMITTED----------")
        console.log(data);

        const reqBody: any =
        {
            project_name: data.project_name,
            project_type: data.project_type,
            customer_id: data.customer_id
        }

        console.log(reqBody);

        if (operation === 'edit') {
            reqBody.project_id = clickedRow?.project_id
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService[operation === 'edit' ? 'put' : 'post'](ProjectsEndpoints.project(reqData))
            .then((res: any) => {
                showToast('SUCCESS', res.message);
                getProjects();
                resetFields();
                setOpen(false);
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            })
            .finally(()=>{
                setSubmission(false)
            })
        // setLoading(false);
    }

    const resetFields = () => {
        reset
            ({
                project_name: '',
                project_type: undefined,
                customer_id: undefined
            })
        setClickedRow({});
    }


    const TableIcons = (row: any) => {
        const handleEdit = (clickedRow: any) => {
            console.log("------------HANDLE EDIT IS CALLED----------")

            setClickedRow(clickedRow);
            setValue('project_name', clickedRow?.project_name);
            setValue('project_type', clickedRow?.project_type);
            setValue('customer_id', clickedRow?.customer_id)
            setOperation('edit');
            setOpen(true);
        }

        const handleDelete = (clickedRow: any) => {
            console.log("------------HANDLE DELETE IS CALLED----------")
            setClickedRow(clickedRow)
            deleteTaskRef.current.handleClickOpen();
        }

        return (
            <>
                <div style={iconDivStyles}>
                    <div>
                        <img src={EditIcon} onClick={() => {
                            handleEdit(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    <div>
                        <img src={DeleteIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDelete(row.row)
                            }} />
                    </div>
                </div>
            </>
        )
    }

    const columns: any = [
        {
            name: "Project Name",
            selector: "project_name",
            wrap: true,
            minWidth: "125px",
        },
        {
            name: "Project Type",
            selector: "project_type",
            wrap: true,
            minWidth: "200px",
        },
        {
            name: "Customer",
            selector: "customer_id",
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => {
                let custName='-';

                customers?.map((value:any)=>
                {
                    if(row['customer_id']===value.customer_id)
                    custName=value.name;
                })
            return custName;
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            button: true,
            selector: "is_active",
            cell: (row: any) => <div ><TableIcons row={row} /></div>
        },
    ];

    const onConfirm = async () => {
        console.log(clickedRow);
        const reqBody =
        {
            project_id: clickedRow?.project_id
        }

        const header = {
            access_token: loginData?.access_token
        }

        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.delete(ProjectsEndpoints.project(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message)
                getProjects();
            })
    }

    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };




    return <React.Fragment>
        <Loader loading={loading}>

            <Container maxWidth={false} style={styles.container} className='Users'>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} className='display__style__1'>
                        <Btn variant="contained" size="medium"

                        onClick={() => {
                            setOperation('');
                            setOpen(true);
                            resetFields();
                        } } btnText={'Add New Project'} type={'button'}/>
                    </Grid>
                    <Grid item lg={12} md={12}>
                        {/* ------------------LISTING OF USERS------------ */}
                        <Datatable
                            columns={columns}
                            data={projects}
                            noDataComponent={!loading ? projects?.length ? " " :" No records to display " : " "}

                        />
                    </Grid>
                </Grid>
            </Container>


            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={styles.dialogcontainer}>
                    <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "0px" }}>
                        <p className="dialog_title">
                            {operation === 'edit' ? 'Edit Project' : 'Add Project'}
                        </p>
                    </DialogTitle>
                    <form onSubmit={handleSubmit(submit)}>
                        <DialogContent >
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={1}>
                                <Grid item xs={12} lg={12} md={12} style={{ marginTop: "10px" }}>
                                        <Controller
                                            control={control}
                                            name="customer_id"
                                            render={({ field: any }) => (
                                                <DropDownComponent
                                                // autoFocus={true}
                                                    label='Customer*'
                                                    menuItems={customers}
                                                    valueKey='customer_id'
                                                    showNameKey='name'
                                                    onChange={(e: any) => {
                                                        setValue('customer_id', e.target.value)
                                                    }}
                                                    value={getValues('customer_id')}
                                                    error={errors.customer_id ? true : false}
                                                />

                                            )}
                                        />
                                        <Errormessage errormessage={errors.customer_id ? errors.customer_id.message : ''} />
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Input
                                            // required={true}
                                            type="text"
                                            label="Project Name*"
                                            // placeholder='Project name'
                                            register={register('project_name')}
                                            autoFocus={false}
                                            error={errors.project_name ? true : false}
                                        />
                                        <Errormessage errormessage={errors.project_name ? errors.project_name.message : ''} />
                                    </Grid>
<Loader loading={submission}/>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Controller
                                            control={control}
                                            name="project_type"
                                            render={({ field: any }) => (
                                                <DropDownComponent
                                                    label='Project Type*'
                                                    menuItems={dummyPaymentData}
                                                    valueKey='name'
                                                    autoFocus={false}
                                                    showNameKey='name'
                                                    onChange={(e: any) => 
                                                    {
                                                        setValue('project_type', e.target.value)
                                                    }}
                                                    value={getValues('project_type')}
                                                    error={errors.project_type ? true : false}
                                                />
                                            )}
                                        />
                                        <Errormessage errormessage={errors.project_type ? errors.project_type.message : ''} />
                                    </Grid>

                                   
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <Btn
                                variant="outlined"
                                onClick={() => {
                                    resetFields();
                                    setOpen(false);
                                } } btnText={' Cancel'} type={'button'}>
                               
                            </Btn>
                            <Btn type="submit" 
                            variant="contained" btnText={operation === 'edit' ? 'Update' : 'Save'}                                                      >
                                
                            </Btn>
                        </DialogActions>
                    </form>
                </div>
            </Dialog>
            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteTaskRef}
                    description={"Are you sure you want to delete this Project?"}
                    title={"Delete Project"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={onConfirm}
                    onDiscard={onDiscard}
                ></AlertDialog>
            </Suspense>
        </Loader>
    </React.Fragment >
}
export default Projects;