import { Button } from "@mui/material";
import React, { Fragment } from "react";

interface IBtnLoaderProps {
  onClick?: Function;
  btnText: string;
  className?: string;
  type: string;
  color?: string;
  variant?: string;
  disable?: boolean;
  size?: string;
  startIcon?: any;
  endIcon?: any;
  style?:any;
}
const theme = {
  spacing: 8,
};
const Btn: React.FC<IBtnLoaderProps> = (props: any) => {
  return (
    <Fragment>
      <Button
        style={{...props.style , borderRadius: "25px", fontWeight: 600} }
        type={props.type}
        focusRipple={props.focusRipple || false}
        className={props.className}
        onClick={props.onClick}
        variant={props.variant || "contained"}
        size={props.size}
        startIcon={props.startIcon || ""}
        color={props.color ? props.color : "primary"}
        disabled={props.disable || false}
        endIcon={props.endIcon || ""}
      >
        {props.btnText}
      </Button>
    </Fragment>
  );
};

export default Btn;
