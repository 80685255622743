import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Popper,
  Typography,
} from "@mui/material";
import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  Suspense,
} from "react";
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DropDownComponent from "../../components/Select/Select";
import Errormessage from "../../components/Errormessage/Errormessage";
import { showToast } from "../../components/Toast/Toast";
import useLocalStorage from "../../hooks/localStorage";
import useApiService from "../../services/api.service";
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import { useRef, useEffect } from "react";
import Datatable from "../../components/Datatable/Datatable";
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import AlertDialog from "../../components/Alertdialog/Dialog";
import CustomerEndpoints from "./Customers.endpoints";
import Tooltip from "../../components/Tooltip/Tooltip";
import { makeStyles } from "@mui/styles";
import Btn from "../../components/Button/Button";

const styles = {
  container: {
    borderRadius: "20px",
    minHeight: "80vh",
    padding: "3vh",
    width: "auto",
    margin: "0px 0px 0px 230px",
    backgroundColor: "white",
  },
  dialogcontainer: { padding: "15px", width: "600px" },
};

const useStyles = makeStyles({
  columnEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
});
const iconDivStyles = {
  display: "flex",
  cursor: "pointer",
};

const Customers = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ApiService = useApiService();
  const loginData = useLocalStorage.getItem("loginData");
  const [operation, setOperation] = useState("");
  const deleteTaskRef: any = useRef();
  const [clickedRow, setClickedRow]: any = useState({});
  const [customers, setCustomers]: any = useState([]);
  const [submission, setSubmission] = useState(false);

  const getCustomers = async () => {
    setLoading(true);

    const reqBody: any = {
      is_deleted :0
    };

    const header = {
      access_token: loginData?.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: header,
    };

    await ApiService.get(CustomerEndpoints.customer(reqData))
      .then((res: any) => {
        console.log(res);
        setCustomers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // ---------------validation schema-----------------
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    billing_currency: Yup.string().trim().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getCustomers();
  }, []);

  const submit = async (data: any) => {
    // setLoading(true);
    setSubmission(true);
    console.log("-----------FORM SUBMITTED----------");
    console.log(data);

    const reqBody: any = {
      name: data?.name,
      billing_currency: data?.billing_currency,
    };
    if (operation === "edit") {
      reqBody.customer_id = clickedRow?.customer_id;
    }
    const header = {
      access_token: loginData?.access_token,
    };
    const reqData = {
      reqBody: reqBody,
      headers: header,
    };
    await ApiService[operation === "edit" ? "put" : "post"](
      CustomerEndpoints.customer(reqData)
    )
      .then((res: any) => {
        showToast("SUCCESS", res.message);
        getCustomers();
        resetFields();
        setOpen(false);
      })
      .catch((err: any) => {
        showToast("ERROR", err.message);
      })
      .finally(() => {
        setSubmission(false);
      });
    // setLoading(false);
  };

  const resetFields = () => {
    reset({
      name: "",
      billing_currency: undefined,
    });
  };
  const [show, setShow] = useState(false);
  const [projectNames, setProjectNames]: any = useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const[customerName,setCustomerName] =useState();
  const handleMenuClose = () => {
    setShow(false);
  };
  const TableIcons = (row: any) => {
    const handleEdit = (clickedRow: any) => {
      console.log("------------HANDLE EDIT IS CALLED----------");
      setValue("name", clickedRow?.name);
      setValue("billing_currency", clickedRow?.billing_currency);
      setClickedRow(clickedRow);
      setOperation("edit");
      setOpen(true);
    };

    const handleDelete = (clickedRow: any) => {
      console.log("------------HANDLE DELETE IS CALLED----------");
      setClickedRow(clickedRow);
      deleteTaskRef.current.handleClickOpen();
    };
    return (
      <>
        <div style={iconDivStyles}>
          <div>
            <img
              src={EditIcon}
              onClick={() => {
                handleEdit(row.row);
              }}
              className="icon_images"
            />
          </div>
          <div>
            <img
              src={DeleteIcon}
              className="icon_images"
              onClick={() => {
                handleDelete(row.row);
              }}
            />
          </div>
        </div>
      </>
    );
  };
  var i = 0;
  const handleProject = (prop: any, event: React.MouseEvent<HTMLElement>) => {
    console.log(prop, "props");
    if (prop.total_projects > 0) {
      {
        let temp: any[] = [];
        prop.project_name.map((data: any, index: any) => {
          temp.push(data);
          setShow(true);
          setProjectNames(temp);
          setCustomerName(prop.name);
        });
      }
    } else {
      setProjectNames(["No Projects"]);
      setShow(true);
    }
  };

  const HandleProjectClick = (prop: any) => {
    console.log(prop, "Handle roe");
    return (
      <>
        <div>
          <div
            id="basic-button"
            style={{ position: "relative", cursor: "pointer" }}
            onClick={(event) => {
              handleProject(prop.data, event);
            }}
          >
            {prop.data.total_projects}
          </div>
          <div></div>
        </div>
      </>
    );
  };
  const columns: any = [
    {
      name: "Customer Name",
      selector: "name",
      wrap: true,
      minWidth: "125px",
    },
    {
      name: "Total Project",
      wrap: true,
      style:{
marginLeft:"80px"
      },
      minWidth: "125px",
      cell: (data: any) => (
        <div>
          <HandleProjectClick data={data} />
        </div>
      ),
    },
    {
      name: "Actions",
      allowOverflow: true,
      button: true,
      selector: "is_active",
      cell: (row: any) => (
        <div>
          <TableIcons row={row} />
        </div>
      ),
    },
  ];

  const onConfirm = async () => {
    console.log(clickedRow);
    const reqBody = {
      customer_id: clickedRow?.customer_id,
    };

    const header = {
      access_token: loginData?.access_token,
    };

    const reqData = {
      reqBody: reqBody,
      headers: header,
    };

    await ApiService.delete(CustomerEndpoints.customer(reqData)).then(
      (res: any) => {
        deleteTaskRef.current.handleClose();
        showToast("SUCCESS", res.message);
        getCustomers();
      }
    );
  };

  const onDiscard = () => {
    deleteTaskRef.current.handleClose();
  };

  const currencyInfo = 
  [
    { name: 'USD' },
    { name: 'INR' },
    { name: 'AED' },
    { name: 'GBP' },
    { name: 'EUR' },
    { name: 'CAD' }, 
];

  return (
    <React.Fragment>
      <Loader loading={loading}>
        <Container maxWidth={false} style={styles.container} className="Users">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} className="display__style__1">
              <Btn type={"contained"}
              onClick={() => {
                setOperation("");
                setOpen(true);
                resetFields();
              }}
              btnText={"Add New Customer"}
              />
            </Grid>
            <Menu
              id="basic-menu"
              open={show}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              style={{ position: "absolute" }}
            >

            </Menu>
            <Grid item lg={12} md={12}>
              {/* ------------------LISTING OF USERS------------ */}
              <Datatable
                columns={columns}
                data={customers}
                noDataComponent={
                  !loading
                    ? customers?.length
                      ? " "
                      : " No records to display "
                    : " "
                }
              />
            </Grid>
          </Grid>
        </Container>

        <Dialog
          open={show}
          onClose={() => {
            setShow(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={styles.dialogcontainer}>
            <DialogTitle
              id="alert-dialog-title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "0px",
              }}
              
            >
              <p className="dialog_title">
              <b> {customerName}</b>
              </p>
            </DialogTitle>
            </div>
            <DialogContent > 
                <DialogContentText id="alert-dialog-description" >
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12} md={12}>
                      </Grid>
                      </Grid>
                      <p style={{textAlign:"center"}}>
                      <b>Project Name - </b>
                      
                      {projectNames.join(' , ')}
                      </p>
                      </DialogContentText>
                      </DialogContent>

           
            </Dialog>




        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={styles.dialogcontainer}>
            <DialogTitle
              id="alert-dialog-title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "0px",
              }}
            >
              <p className="dialog_title">
                {operation === "edit" ? "Edit Customer" : "Add Customer"}
              </p>
            </DialogTitle>
            <form onSubmit={handleSubmit(submit)}>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12} md={12}>
                      <Input
                        // required={true}
                        type="text"
                        autoFocus={false}
                        label="Customer Name*"
                        register={register("name")}
                        // autoFocus={true}
                        onChange={(e: any) => {
                          setValue("name", e.target.value);
                        }}
                        error={errors.name ? true : false}
                      />
                      <Errormessage
                        errormessage={errors.name ? errors.name.message : ""}
                      />
                    </Grid>
                    <Loader loading={submission} />
                    <Grid item xs={12} lg={12} md={12}>
                      <Controller
                        control={control}
                        name="billing_currency"
                        render={({ field: any }) => (
                          <DropDownComponent
                            label="Billing Currency*"
                            menuItems={currencyInfo}
                            valueKey="name"
                            showNameKey="name"
                            value={getValues("billing_currency")}
                            onChange={(e: any) => {
                              setValue("billing_currency", e.target.value);
                            }}
                            error={errors.billing_currency ? true : false}
                          />
                        )}
                      />
                      <Errormessage
                        errormessage={
                          errors.billing_currency
                            ? errors.billing_currency.message
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>

              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Btn
                  variant="outlined"
                  onClick={() => {
                    resetFields();
                    setOpen(false);
                  }}
                  btnText={"Cancel"}
                  type={"button"}
                />

                <Btn
                  // type={"contained"}
                  // btnText={operation === "edit" ? "Edit  " : "Save"}
                  type="submit"
                  variant="contained"
                  btnText={operation === "edit" ? "Update" : "Save"}
                />
              </DialogActions>
            </form>
          </div>
        </Dialog>
        <Suspense fallback={""}>
          <AlertDialog
            ref={deleteTaskRef}
            description={"Are you sure you want to delete this Customer?"}
            title={"Delete Customer"}
            confirm={"Confirm"}
            discard={"Discard"}
            onConfirm={onConfirm}
            onDiscard={onDiscard}
          ></AlertDialog>
        </Suspense>
      </Loader>
    </React.Fragment>
  );
};
export default Customers;
