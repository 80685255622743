import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import Loader from '../../components/Loader/Loader';
import './Users.scss';
// -------------------ICONS IMAGES--------------
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import useApiService from '../../services/api.service';
import useLocalStorage from '../../hooks/localStorage';
import UsersEndpoints from './Users.endpoints';
import { showToast } from '../../components/Toast/Toast';
import AlertDialog from '../../components/Alertdialog/Dialog';
import { Suspense } from 'react';
//---------------------------------------------------
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import Errormessage from '../../components/Errormessage/Errormessage';
import Input from "../../components/Input/Input";
import { setConstantValue } from 'typescript';
import DropDownComponent from '../../components/Select/Select';
import { makeStyles } from '@mui/styles';
import Tooltip from '../../components/Tooltip/Tooltip';
import DepartmentEndpoints from '../Departments/Deprtment.endpoints';
import Btn from '../../components/Button/Button';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto",
    margin: "0px 0px 0px 230px",
    backgroundColor:"white" },
    dialogcontainer: { padding: '15px' }
}


const useStyles = makeStyles({
    columnEllipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "block",
    },
  });


const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}
const dummyData: any = [
    { user_id: 1, name: "Rahul", email: "rahul@gmail.com", user_type: 1 },
    { user_id: 2, name: "Anshu", email: "anshu@gmail.com", user_type: 2 },
    { user_id: 3, name: "Shashwat", email: "shashwat@gmail.com", user_type: 0 },
    { user_id: 4, name: "Jasneet", email: "jasneet@gmail.com", user_type: 1 },
    { user_id: 5, name: "Naman", email: "naman@gmail.com", user_type: 2 },
    { user_id: 6, name: "Raghav", email: "raghav@gmail.com", user_type: 1 },
]

const usersType: any =
    [
        { id: 1, name: 'Admin' },
        { id: 2, name: 'Project Manager' },
        { id: 3, name: 'Lead' },
    ]

const Users = (props:any) => {
    const classes = useStyles()
    const loginData = useLocalStorage.getItem("loginData")
    const [loading, setLoading] = useState(true);
    const deleteTaskRef: any = useRef();
    const [userData, setUserData] = useState([]);
    const [clickedRow, setClickedRow]: any = useState();
    const [open, setOpen] = useState(false);
    const [operation, setOperation] = useState(true);
    const [submissiom, setSubmissiom] = useState(false)
    const ApiService = useApiService();
const [departments, setDepartments]:any = useState([])
const [departmentLoading, setDepartmentLoading] = useState(false)
const [showDepartment, setShowDepartment] = useState(false)
    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .required('Name is required'),
        email:
            Yup.string().required('Email is required').
                email('Email format is wrong'),
        user_type: Yup.string().required('User type is required'),
        department_id : showDepartment ? Yup.number().required('Department is required').nullable()
        : Yup.number().nullable()
        
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset,
        control
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    

    const showTooltip = (data: any) => {
        if (data && data.length > 20) {
          return (
            <Tooltip
              content={data}
              direction={"top"}
              style={{
                backgroundColor: "rgba(97, 97, 97, 0.92)",
                marginLeft:"75px"
              }}
              wrapperStyle={{
                width: "150px",
              }}
            >
              <span className={classes.columnEllipsis}>{data}</span>
            </Tooltip>
          );
        } else {
          return <span>{data}</span>;
        }
      };


    const getUsers = async () => {
        setLoading(true);

        const reqBody: any = {};
        const header =
        {
            access_token: loginData?.access_token
        }

        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(UsersEndpoints.users(reqData))
            .then((res: any) => {
                setUserData(res.data);
            }).finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 1500);
               
            });
    }


    useEffect(() => {
        getUsers();
    }, [])

    const onConfirm = async () => {

        console.log(clickedRow);

        const reqBody =
        {
            user_id: clickedRow?.user_id
        }
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.delete(UsersEndpoints.users(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message)
                getUsers();
            })
    }

    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };

    const TableIcons = (row: any) => {
        const handleDepartmentEdit=(clickedRow:any)=>{
    if(clickedRow?.department_id){
        setShowDepartment(true)
        setValue("department_id",clickedRow.department_id)
    }else{
        setShowDepartment(false)
    }
        }
        const handleEdit = (clickedRow: any) => {
            setDepartmentLoading(true)
            console.log("------------HANDLE EDIT IS CALLED----------")
            setOperation(false);
            setValue('email', clickedRow?.email);
            setValue('name', clickedRow?.name);
            setValue('user_type', clickedRow?.user_type)
            handleDepartmentEdit(clickedRow)
            setClickedRow(clickedRow);
            getDepartments()
            setOpen(true);
            
        }


        const handleDelete = (clickedRow: any) => {
            console.log("------------HANDLE DELETE IS CALLED----------")
            setClickedRow(clickedRow)
            deleteTaskRef.current.handleClickOpen();
        }

        return (
            <>
                <div style={iconDivStyles}>
                    <div>
                        <img src={EditIcon} onClick={() => {
                            handleEdit(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    <div>
                        <img src={DeleteIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDelete(row.row)
                            }} />
                    </div>
                </div>
            </>
        )
    }

    const columns: any = [
        {
            name: "Name",
            selector: "name",
            wrap: true,
            // sortable: true,
            minWidth: "125px",
        },
        {
            name: "Email Id",
            selector: "email",
            wrap: true,
            minWidth: "125px",
            cell:(row:any)=>showTooltip(row['email'])
        },
        {
            name: "Role",
            selector: "user_type",
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => {
                if (row['user_type'] === 0)
                    return 'Super Admin';
                else if (row['user_type'] === 1)
                    return 'Admin';
                else if (row['user_type'] === 2)
                    return 'Project Manager';
                return 'Team Lead'
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            button: true,
            selector: "is_active",
            cell: (row: any) => <div ><TableIcons row={row} /></div>
        },
    ];

    const onSubmit = async (data: any) => {
      setSubmissiom(true)

        console.log("----------FORM SUBMITTED----------");

        const reqBody: any =
        {
            name: data.name,
            email: data.email,
            user_type: data.user_type,
            department_id : data?.department_id ? data?.department_id : null
        }


        if (!operation) {
            reqBody.user_id = clickedRow?.user_id;
        }

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService[operation === true ? "post" : "put"](UsersEndpoints.users(reqData))
            .then((res: any) => {
                showToast('SUCCESS', res.message);
                
                setOpen(false)
            }).
            catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setSubmissiom(false)
                // setLoading(false);
                getUsers();
            })
    }

    const clearFields = () => {
        reset({
            name: '',
            email: '',
        });
    }


    const getDepartmentsFromApi= async() =>{
        setDepartmentLoading(true);

        const reqBody: any = {};

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                setDepartments(res.data)
               }).finally(() => {
                setTimeout(() => {
                    setDepartmentLoading(false);
                }, 1000);
            });
    }

    const getDepartments = () =>{
    if(getValues('user_type') == 3){
setShowDepartment(true)
            getDepartmentsFromApi()

    } else{
        setDepartmentLoading(false)
        setShowDepartment(false)
        setValue("department_id",null)
        return 
    }
       
    }

    return <React.Fragment>
        <Loader loading={loading}>
            <Container maxWidth={false} style={styles.container} className='Users'>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} className='display__style__1'>
                        <Btn variant="contained" size="medium"
                        onClick={() => {
                            setShowDepartment(false);
                            setOpen(true);
                            setOperation(true);
                            clearFields();
                        } } btnText={'Add New User'} type={'button'}                        
                        />
                    </Grid>
                    <Grid item lg={12} md={12}>
                        {/* ------------------LISTING OF USERS------------ */}
                        <Datatable
                            columns={columns}
                            data={userData}
                            noDataComponent={!loading ? userData?.length ? " " :" No records to display " : " "}

                        />
                    </Grid>
                </Grid>
            </Container>


            <Container>
                <Dialog
                    open={open}
                    onClose={() => {
                        setOpen(false)
                        setTimeout(() => {
                            setOperation(true)
                        }, 200)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={styles.dialogcontainer}>
                            <Grid item sm={12}
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                style={{ cursor: "pointer" }}>
                                <CloseIcon onClick={() => {
                                    setOpen(false)
                                    setTimeout(() => {
                                        setOperation(true)
                                    }, 200)
                                }} />
                            </Grid>

                            <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: " 0px" }}>
                                <p className="dialog_title">
                                    {operation === true ? 'Add User' : 'Edit User'}
                                </p>
                            </DialogTitle>

                            <DialogContent >
                                <DialogContentText id="alert-dialog-description">
                                    {/* ------------------TEXT FIELD GOES HERE------------- */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Input
                                                // required={true}
                                                type="text"
                                                label="Username*"
                                                register={register('name')}
                                                onChange={(e: any) => {
                                                    setValue('name', e.target.value);
                                                }}
                                                autoFocus={true}
                                                error={errors.name ? true : false}
                                            />
                                            <Errormessage errormessage={errors.name ? errors.name.message : ''} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Input
                                                style={{marginTop:"-1px"}}
                                                // required={true}
                                                type="email"
                                                label="Email*"
                                                register={register('email')}
                                                onChange={(e: any) => {
                                                    setValue('email', e.target.value);
                                                }}
                                                autoFocus={false}
                                                error={errors.email ? true : false}
                                            />
                                            <Errormessage errormessage={errors.email ? errors.email.message : ''} />
                                        </Grid>
                                        <Loader loading ={departmentLoading}/>

                                        <Loader loading={submissiom}/>
                                        <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                name="user_type"
                                                render={({ field: any }) => (
                                                    <DropDownComponent
                                                        valueKey='id'
                                                        showNameKey='name'
                                                        // showCreateOption={false}
                                                        label='User Type*'
                                                        menuItems={usersType}
                                                        // register={register('user_type')}
                                                        value={getValues('user_type')}
                                                        onChange={(e: any) => {
                                                            setValue('user_type', e.target.value);
                                                            getDepartments()
                                                        }}
                                                        error={errors.user_type ? true : false}
                                                    />
                                                )}
                                            />
                                            <Errormessage errormessage={errors.user_type ? errors.user_type.message : ''} />
                                        </Grid>
                                        {showDepartment && !departmentLoading &&
                                        <Grid item xs={12}>
                                        <Controller
                                                        control={control}
                                                        name={`department_id`}
                                                        render={({ field: any }) => (
                                                            <DropDownComponent
                                                                // disabled={operation === 'view'}
                                                                label='Department*'
                                                                menuItems={departments}
                                                                valueKey='department_id'
                                                                showNameKey='name'
                                                                // showCreateOption={true}
                                                                // popupFunction={(e: any) => {
                                                                //     departmentTaskRef.current.handleClickDepartmentDialogOpen()
                                                                // }}
                                                                value={getValues(`department_id`)}
                                                                onChange={(e: any) => {
                                                                    setValue(`department_id`, e.target.value);
                                                                }}
                                                                error={showDepartment && errors?.department_id ? true : false}
                                                            />
                                                        )}
                                                    />
                                             <Errormessage errormessage={showDepartment && errors.department_id ? errors.department_id.message : ''} />

                                        </Grid>
}
                                        {/* <DropDownComponent
                                            valueKey='id'
                                            showNameKey='name'
                                            label='Usertype'
                                            menuItems={usersType}
                                            register={register('user_type')}
                                            value={getValues('user_type')}
                                        /> */}

                                    </Grid>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                                <Btn
                                    variant="outlined"
                                    onClick={() => {
                                        setOpen(false);
                                        setTimeout(() => {
                                            setOperation(true);
                                        }, 200);
                                    } } btnText={'Cancel'} type={'button'}/>
                                    
                                <Btn type="submit" variant="contained" btnText={operation === true ? 'Save' : 'Update'}/>
                                    
                            </DialogActions>
                        </div>
                    </form>
                </Dialog>
            </Container>

            <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteTaskRef}
                    description={"Are you sure you want to delete this user?"}
                    title={"Delete User"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={onConfirm}
                    onDiscard={onDiscard}
                ></AlertDialog>
            </Suspense>
        </Loader>
    </React.Fragment>
}

export default Users