import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss'
// ---------------TWO TYPES OF TOAST ARE THERE--------------
// 1.SUCCESS
// 2.ERROR
export const showToast = (type: any, message: any) => {

    switch (type) {
        case 'SUCCESS':toast.success(message)
            break;
        case 'ERROR':toast.error(message)
            break;
    }
}

const Toast = () => {
    return <>
        <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            theme='colored'
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </>
}

export default Toast;