import
Popper,
{
  PopperPlacementType
} from '@mui/material/Popper';
import { Box, ClickAwayListener, Fade, Paper } from '@mui/material';
import './Sidebar.scss'

import { makeStyles } from '@mui/styles';
import React, { ReactElement } from "react";
interface Props {
  content: ReactElement;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  placement?: PopperPlacementType;
}

const useStyles = makeStyles((theme: any) => {
  // const color = theme.palette.background.paper; 
  return {
    popoverRoot: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
    },
    // popperbottom: {
    //   top: '45px !important',
    //   left: '19px !important'
    // },
    content: {
      padding: "8px !important",
    },
    popper: {
      position: "absolute",
      borderRadius: "20px !important",
      // transform: translate3d(280px, 279px, 0px);
      top: "-345px !important",
      backgroundColor: "white !important",
      left: '255px !important',
      width: "230px !important",
      // will-change: transform;
      zIndex: 2000,
    },
    arrow: {
      overflow: "hidden",
      position: "absolute",
      top: "10px !important",
      left: "-9px !important",
      // borderBottom: "1px solid grey",
      transform: "rotate(30deg) !important",
      width: "1em",
      height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
      boxSizing: "border-box",
      color: "white",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        // boxShadow: "none",
        backgroundColor: "currentColor",
        transform: "rotate(120deg)"
      }
    }
  };
});

const SidebarPoper = ({
  placement = "right",
  arrow = true,
  open,
  onClose = () => { },
  content,
  children
}: Props) => {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [childNode, setChildNode] = React.useState<HTMLElement | null>(null);

  return (
    <div className='Sidebar'>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        transition
        className={classes.popper}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }: any) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={onClose}>
                <Paper className={classes.popoverRoot}>
                  {arrow ? (
                    <span className={classes.arrow} ref={setArrowRef} />
                  ) : null}
                  <Box className={classes.content}>{content}</Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default SidebarPoper;
