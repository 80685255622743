import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { useImperativeHandle, useState, forwardRef } from 'react';
import Input from '../../../components/Input/Input';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DropDownComponent from '../../../components/Select/Select';
import useApiService from '../../../services/api.service';
import useLocalStorage from '../../../hooks/localStorage';
import Loader from '../../../components/Loader/Loader';
import { showToast } from '../../../components/Toast/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Errormessage from '../../../components/Errormessage/Errormessage';
import CustomerEndpoints from '../../Customers/Customers.endpoints';
import Btn from '../../../components/Button/Button';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "80px 40px 40px 260px" },
    dialogcontainer: { width: "550px", padding: '10px 0px' }
}

const currencyInfo =
    [
        { name: 'USD' },
        { name: 'INR' },
        { name: 'AED' },
        { name: 'GBP' },
        { name: 'EUR' },
        { name: 'CAD' }, 
    ]


const CustomerDialog: React.FC<any> = forwardRef((props: any, ref: any) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const ApiService = useApiService();
    const loginData = useLocalStorage.getItem("loginData");

    useImperativeHandle(ref, () =>
    ({
        handleClickCustomerDialogOpen: () => {
            setOpen(true);
        },
        handleClickCustomerDialogClose: () => {
            setOpen(false);
        }
    }));

    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        customer_name: Yup.string()
            .trim()
            .required('Name is required'),
        customer_billing_currency: Yup.string().required('This field is required')
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset
    } = useForm
            ({
                resolver: yupResolver(validationSchema)
            });



    const resetFields = () => {
        reset
            ({
                customer_name: '',
                customer_billing_currency: ''
            })
    }

    const submitCustomer = async (data: any) => {
        setLoading(true);

        console.log("-----------FORM SUBMITTED----------")
        console.log(data);

        const reqBody =
        {
            name: data.customer_name,
            billing_currency: data.customer_billing_currency,
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.post(CustomerEndpoints.customer(reqData))
            .then((res: any) => {
                props.updateCustomers({ customer_id: res.data.customer_id, name: data.customer_name, email: data.customer_email, billing_currency: data.customer_billing_currency });
                showToast('SUCCESS', res.message);
                //---------RESET THE FIELDS----------//
                resetFields();
                //--------UPDATE CUSTOMERS IN THE LIST//
                setOpen(false);
            })
            .catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            })
        setLoading(false);
    }


    return <React.Fragment>
        <Loader loading={loading}>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={styles.dialogcontainer}>
                    <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "0px" }}>
                        <p className="dialog_title">
                            SOW Customer
                        </p>
                    </DialogTitle>

                    <form >
                        <DialogContent >
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Input
                                            // required={true}
                                            type="text"
                                        label="Customer Name*"
                                            register={register('customer_name')}
                                            autoFocus={true}
                                            error={errors.customer_name ? true : false}
                                        />
                                        <Errormessage errormessage={errors.customer_name ? errors.customer_name.message : ''} />
                                    </Grid>


                                    <Grid item xs={12} lg={12} md={12}>
                                        <Controller
                                            control={control}
                                            name="customer_billing_currency"
                                            render={({ field: any }) => (
                                                <DropDownComponent
                                                    label='Billing Currency*'
                                                    menuItems={currencyInfo}
                                                    valueKey='name'
                                                    showNameKey='name'
                                                    value={getValues('customer_billing_currency')}
                                                    onChange={(e: any) => {
                                                        setValue('customer_billing_currency', e.target.value)
                                                    }}
                                                    error={errors.customer_billing_currency ? true : false}
                                                />
                                            )}
                                        />
                                        <Errormessage errormessage={errors.customer_billing_currency ? errors.customer_billing_currency.message : ''} />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <Btn
                                variant="outlined"
                                onClick={() => {
                                    resetFields();
                                    setOpen(false);
                                } } btnText={'Cancel'} type={'button'}>
                                
                            </Btn>
                            <Btn type="button" variant="contained" onClick={handleSubmit(submitCustomer)} btnText={'Save'}>
                                
                            </Btn>
                        </DialogActions>
                    </form>
                </div>

            </Dialog>
        </Loader>
    </React.Fragment>
})

export default CustomerDialog;