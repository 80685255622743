import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, FormControlLabel, Grid, InputLabel, Link, List, TextField } from '@mui/material';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import Loader from '../../components/Loader/Loader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useApiService from '../../services/api.service';

//------------------ICONS Import -----------------
import ViewIcon from "../../assets/images/eye.svg";
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import DownloadIcon from "../../assets/images/download.svg";
//---------------------------------------------------------

import moment from 'moment';
import getCurrencySymbol from '../Statementofwork/getCurrencySymbol';
import InvoiceEndpoints from './Invoices.endpoints';
import useLocalStorage from '../../hooks/localStorage';
import { showToast } from '../../components/Toast/Toast';
import { makeStyles } from '@material-ui/styles';

import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../components/Alertdialog/Dialog';
import Btn from '../../components/Button/Button';
import { Close, FilterAlt, FilterAltOffOutlined } from '@mui/icons-material';
import Tooltip from '../../components/Tooltip/Tooltip';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto",
    margin: "0px 0px 0px 230px",
    backgroundColor:"white" },
    dialogcontainer: { padding: '15px' }
}

const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}

const useStyles = makeStyles({
    tooltipTop: {
        "&::before": {
            borderTopColor: "rgba(97, 97, 97, 0.92) !important",
        },
        fontSize: "10px",
    },
    columnEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        cursor: "pointer"
    },
})
const Invoice=()=>{
    const classes = useStyles()
    // States and all
    const [loading, setLoading] = useState(true);
    const [operation, setOperation] = useState('');
    const loginData = useLocalStorage.getItem("loginData")
    const [open, setOpen]: any = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [currency, setCurrency]: any = useState('');
    const ApiService = useApiService();
    const navigate = useNavigate();
    const [clickedRow, setClickedRow]: any = useState({});
    const deleteTaskRef: any = useRef();
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
const defaultColumns = [
    { name: 'Project Name', checked: true },
    { name: 'Customer Name', checked: true },
    { name: 'Invoice Date', checked: true },
    { name: 'Total Amount', checked: true },
    { name: 'Invoice Status', checked: true },
 ]

 useEffect(() => {
    if (useLocalStorage.getItem('Invoice-checkbox')) {
        setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('Invoice-checkbox')))
    }
}, [])

const drawerClose = () => () => {
    setColumnFilterOpen(false);
    setFilterCheckboxs(defaultColumns)
    useLocalStorage.removeItem("Invoice-checkbox")
};

const list = (anchor: any) => (
    <Box
        sx={{ width: 250, margin: "60px 0px 0px 20px" }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    >
        <List>
              <div style={{display:"flex" , justifyContent:"space-between" , margin:"10px 17px 10px 0px" , alignItems:"center"}}>
        
            <h2>Column Filters</h2>
            <Close  style={{cursor:"pointer"}} onClick={()=>{
if (useLocalStorage.getItem('Invoice-checkbox')) {
setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('Invoice-checkbox')))
setColumnFilterOpen(false)
}
else {
setFilterCheckboxs(defaultColumns)
setColumnFilterOpen(false)
}
        }}/>
        </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
                {filterCheckboxs?.map((item: any, index: number) => (
                    <div>
                        <FormControlLabel
                            key={item.name}
                            label={item.name}
                            control={
                                <Checkbox
                                    {...register(`checkboxes.${index}`)}
                                    disableRipple={true}
                                    disableTouchRipple={true}
                                    style={{
                                        padding: "10px 10px"
                                    }}
                                    checked={item.checked}
                                    onChange={() => handleCardFilterValueChange(item.name)}
                                />
                            }
                        />
                    </div>
                ))}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-start", gap: "5px", margin: "20px 0px 0px 0px" }}>

                <Btn
                    variant='outlined'
                    type="button"
                    btnText='Reset'
                    onClick={drawerClose()}
                />
                <Btn
                    variant='contained'
                    type="button"
                    btnText='Apply'
                    onClick={handleFilteration}
                />
            </div>


        </List>
    </Box>
);

const toggleDrawer =
(anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleFilteration = () => {
        // console.log(getValues('checkboxes'),"======================data on apply=======================")
        useLocalStorage.setItem('Invoice-checkbox', JSON.stringify(filterCheckboxs))
        console.log(filterCheckboxs, "===========this is what we want=====")
        setColumnFilterOpen(false)

    }

 const handleCardFilterValueChange = (name: any) => {
    // handle checkbox value changes
    setFilterCheckboxs((prevState: any) => {
        console.log(prevState, "====prevsstate")
        // if "All" clicked and checked uncheck everything else check everything.
        const modifiedPrevState = prevState.map((item: any, index: any) => {
            if (item.name === name) {
                // setValue(`checkboxes.${index}`,{ name:item.name, checked: !item.checked })
                return { name: item.name, checked: !item.checked };
            } else {
                // setValue(`checkboxes.${index}`,{ name:item.name, checked: item.checked })
                return { name: item.name, checked: item.checked };
            }
        });
        console.log(modifiedPrevState, "----modifiedddd")
        // console.log(getValues('checkboxes'),"======================data on apply=======================")
        return modifiedPrevState

    })
}
 const [filterCheckboxs, setFilterCheckboxs]: any = useState(defaultColumns);

    const onConfirm = async () => {
        console.log(clickedRow);
        const reqBody =
        {
            invoice_id: clickedRow?.invoice_id
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.delete(InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message)
                getInvoices();
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {
                setLoading(false);
            })
    }

    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };



    const [autoCompleteValues, setAutocompleteValues]: any = useState
        ({
            customer_sows: [],
            customer_milestones: []
        })

    const [dropdownValues, setDropdownValues]: any = useState
        ({
            customer_id: '',
            invoice_status: ''
        })
        
    //------------------------------- Get Invoices------------------------------------
    
    const getInvoices = async () => {

        setLoading(true);
        const reqBody: any = {};
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.get(InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                console.log(res.data);
                setInvoiceData(res.data);
            }).finally(() => {
                setLoading(false);
            });
    }
  
    //---------------------------------------------------------------------------------
    useEffect(() => {
  //      getDropdowndata();
        getInvoices();
        ///////////set the amount to 0//////
        reset({
            amount: 0,
            department: [],
            salesperson: []
            })
    }, [])


    //-----------------------VALIDATION SCHEMA---------------------------------------
        const validationSchema = Yup.object().shape({
            customer_id:
                Yup.string()
                    .trim()
                    .required('This field is required'),
            amount: Yup.number()
                .typeError('Amount must be a number')
                .required("This field is required")
                .min(0, "Please enter a valid amount"),
            due_date: Yup.date()
                .when("invoice_date",
                    (invoice_date, Yup) => invoice_date && Yup.min(invoice_date, "Enter a valid end date")),
            salesperson: Yup.array().of(
                Yup.object().shape({
                    sales_person_id: Yup.string().required("This field is required"),
                    sales_person_share: Yup.number()
                        .typeError('Share must be a number')
                        .required("This field is required")
                        .min(1, "Please enter a valid share %")
                        .max(100, "Please enter a valid share %"),
                })
            ),
            department: Yup.array().of(
                Yup.object().shape({
                    department_id: Yup.string().required("This field is required"),
                    department_share: Yup.number()
                        .typeError('Share must be a number')
                        .required("This field is required")
                        .min(1, "Please enter a valid share %")
                        .max(100, "Please enter a valid share %")
                })
            ),
            milestone: Yup.array().of(
                Yup.object().shape({
                    description: Yup.string().required("This field is required"),
                    unit_price: Yup.number()
                        .typeError('Amount must be a number')
                        .required("This field is required")
                        .min(0, "Please enter a valid amount"),
                    quantity: Yup.number()
                        .typeError('Quantity must be a number')
                        .required("This field is required")
                        .min(0, "Please enter a valid quantity"),
                })
            )
        })

    //--------------------------------------------------------------------------------

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset,
        control
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    // ------------------------------------ RESET FIELDS-----------------------------------
    const resetFields = async () => {
        setValue('customer_id', undefined);
        setCurrency('');
        setAutocompleteValues({
            customer_sows: [],
            customer_milestones: []
        })
        setDropdownValues
            ({
                customer_id: '',
                invoice_status: ''
            })
        reset
            ({
                invoice_date: new Date().toISOString(),
                due_date: new Date().toISOString(),
                comments: '',
                amount: 0,
                milestoneFields: [],
                salespersonFields: [],
            })
        setValue('milestone', []);
        setValue('department', []);
        setValue('salesperson', []);
    }
    //----------------------------------------------------------------------------------


    const TableIcons = (row: any) => {
        const handleEdit = (clickedRow: any) => {
            console.log("------------HANDLE EDIT IS CALLED----------")
            navigate('/add/invoices' , {state:{operation:"edit",invoice_id:clickedRow?.invoice_id,clickedRowData:clickedRow}})
            
        }
        const handleDelete = (clickedRow: any) => {
            console.log("------------HANDLE DELETE IS CALLED----------")
            setClickedRow(clickedRow)
            deleteTaskRef.current.handleClickOpen();
        }
        const handleView = (clickedRow: any) => {
            console.log("------------HANDLE VIEW IS CALLED----------")
            navigate('/add/invoices' , {state:{operation:"view",invoice_id:clickedRow?.invoice_id,clickedRowData:clickedRow}})
        }

        const handleDownload = async (clickedRow: any) => {
            console.log("----------------HANDLE DOWNLOAD IS CALLED--------")
            console.log(clickedRow);
            setLoading(true);
            const reqBody: any =
                { invoice_id: clickedRow?.invoice_id };
            const header =
                { access_token: loginData?.access_token }
            const reqData = {
                reqBody: reqBody,
                headers: header
            };
            await ApiService.post(InvoiceEndpoints.invoice_pdf(reqData))
                .then((res: any) => {
                    window.open(res.data.url);
                }).catch((err: any) => {
                    showToast('ERROR', err.message);
                }).finally(() => {
                    setLoading(false);
                });
        }

        return (
            <>
                <div style={iconDivStyles}>
                    <div>
                        <img src={ViewIcon} onClick={() => {
                            handleView(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    <div>
                        <img src={EditIcon} onClick={() => {
                            handleEdit(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    <div>
                        <img src={DeleteIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDelete(row.row)
                            }} />
                    </div>
                    <div>
                        <img src={DownloadIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDownload(row.row)
                            }} />
                    </div>
                </div>
            </>
        )
    }
    const showTooltip = (data: any) => {
        if (data && data.length > 11) {
            return (
                <Tooltip
                    content={data}
                    direction={"top"}
                    style={{
                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                        // marginTop: "-40px",
                        // marginLeft: "65px"
                    }}
                    wrapperStyle={{
                        width: "115px",
                    }}
                >
                    <span className={classes.columnEllipsis}>{data}</span>
                </Tooltip>
            );
        } else {
            return <span>{data}</span>;
        }
    };
    // -----------------------------------COLUMS FIELD-----------------------------------
    const columns: any = [
        {
            name: "Project Name",
            selector: "project_name",
            wrap: true,
            omit: useLocalStorage.getItem("Invoice-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Invoice-checkbox'))?.[0]?.checked == false : false,
            
            minWidth: "175px",
            cell:(row:any) => {
                return <Link underline='none' style={{cursor:"pointer"}} onClick={()=>            navigate('/add/invoices' , {state:{operation:"view",invoice_id:row?.invoice_id,clickedRowData:row}})
            }> {showTooltip(row['project_name'])}</Link>
            }
        },
        {
            name: "Customer Name",
            selector: "customer_name",
            omit: useLocalStorage.getItem("Invoice-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Invoice-checkbox'))?.[1]?.checked == false : false,
            
            wrap: true,
            minWidth: "175px",
        },
        {
            name: "Invoice Date",
            selector: "invoice_date",
            wrap: true,
            omit: useLocalStorage.getItem("Invoice-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Invoice-checkbox'))?.[2]?.checked == false : false,
            
            minWidth: "125px",
            cell: (row: any) => moment(row['invoice_date']).format('ll')
        },
        {
            name: "Total Amount",
            selector: "amount",
            wrap: true,
            omit: useLocalStorage.getItem("Invoice-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Invoice-checkbox'))?.[3]?.checked == false : false,
            
            minWidth: "150px",
            cell: (row: any) => {
                return getCurrencySymbol(row['billing_currency']) + " " + (row['amount'].toLocaleString("en-IN"))
            }
        },
        {
            name: "Invoice Status",
            selector: "invoice_status",
            wrap: true,
            omit: useLocalStorage.getItem("Invoice-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Invoice-checkbox'))?.[4]?.checked == false : false,
            
            minWidth: "180px",
            cell: (row: any) => {
                switch (row['invoice_status']) {
                    case 1: return 'Draft'
                    case 2: return 'Partially Completed'
                    default: return 'Completed'
                }
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            button: true,
            minWidth: '150px',
            selector: "is_active",
            style: {
                justifyContent: 'flex-start !important'
            },
            cell: (row: any) => <div ><TableIcons row={row} /></div>
        },
    ];
    //--------------------------------------------------------------------------------------

    const handleCreateInvoice=()=>{
        navigate('/add/invoices' , {state:{operation:"add"}})

    }

    return <React.Fragment>
    <Loader loading={loading}>
        <Container maxWidth={false} style={{ ...styles.container, display: !open ? 'block' : 'none' }} className='Users'>
            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Grid item lg={6} md={6} className='display__style__1'>
                    {<Btn  onClick={() => {
                            handleCreateInvoice();
                        } } btnText={'Create New Invoice'} type={'contained'} />
                    }
                </Grid>

                <Grid item lg={1} md={1} className='display__style__1'>
                                <Tooltip
                                    content={'Column Filters'}
                                    direction={"top"}
                                    style={{
                                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                                        marginTop: "-10px",
                                        marginLeft:"-30px",
                                    }}
                                    wrapperStyle={{
                                        width: "-webkit-fill-available",
                                    }}
                                    hideOnClick={true}
                                    className={classes.tooltipTop}
                                >
                                    <div className="jobList__notification">
                                        <div
                                            className="filterButton"
                                            style={{ transform: "none" , display:"flex" , justifyContent:"center", cursor:"pointer" }}
                                            onClick={() => setColumnFilterOpen(!columnFilterOpen)}
                                        >
                                            <FilterAlt style={{color:"rgba(0,126,191,1)"}} />
                                        </div>
                                        <Drawer
                                            anchor={"right"}
                                            open={columnFilterOpen}
                                            onClose={() => {
                                                if (useLocalStorage.getItem('Invoice-checkbox')) {
                                                    setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('Invoice-checkbox')))
                                                    setColumnFilterOpen(false)
                                                }
                                                else {
                                                    setFilterCheckboxs(defaultColumns)
                                                    setColumnFilterOpen(false)
                                                }
                                            }
                                            }
                                        >
                                            {list('right')}
                                            {/* {columnListFilter()} */}
                                        </Drawer>
                                    </div>
                                </Tooltip>
                            </Grid>
                <Grid item lg={12} md={12}>
                    <Datatable
                        columns={columns}
                        data={invoiceData}
                        noDataComponent={!loading ? invoiceData?.length ? " " : " No records to display " : " "}

                    />
                </Grid>
            </Grid>
        </Container>
        <Suspense fallback={""}>
                <AlertDialog
                    ref={deleteTaskRef}
                    description={"Are you sure you want to delete this Invoice?"}
                    title={"Delete Invoice"}
                    confirm={"Confirm"}
                    discard={"Discard"}
                    onConfirm={onConfirm}
                    onDiscard={onDiscard}
                ></AlertDialog>
            </Suspense>

        </Loader>
        </React.Fragment>



}

export default Invoice;