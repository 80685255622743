import {
     Container, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    Grid, Stack, TextField, Typography,
    Menu, MenuItem, InputLabel, FormControl, Accordion, AccordionSummary, AccordionDetails, FormControlLabel, Checkbox, List, Drawer
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Datatable from '../../components/Datatable/Datatable';
import Loader from '../../components/Loader/Loader';
import './Report.scss';
import Button from '../../components/Button/Button'
// -------------------ICONS IMAGES--------------
// import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import useApiService from '../../services/api.service';
import useLocalStorage from '../../hooks/localStorage';
import { showToast } from '../../components/Toast/Toast';
import AlertDialog from '../../components/Alertdialog/Dialog';
import { Suspense } from 'react';
//---------------------------------------------------
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import Errormessage from '../../components/Errormessage/Errormessage';
import Input from "../../components/Input/Input";
import { couldStartTrivia, setConstantValue } from 'typescript';
import DropDownComponent from '../../components/Select/Select';
import ReportEndpoints from './Report.endpoints';
import Btn from '../../components/Button/Button';
import { Close, DateRangeOutlined, DownloadOutlined, ExpandMoreOutlined, Filter1Outlined, FilterAlt, FilterAltOffOutlined, FilterOutlined } from '@mui/icons-material';
import Statementofworkendpoints from '../Statementofwork/Statementofwork.endpoints';
import DepartmentEndpoints from '../Departments/Deprtment.endpoints';
import Autocomplete from '../../components/Multiselect/Multiselect';
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangeFilter from '../../components/DateRangePicker/DateRangePicker';
import moment, { months } from 'moment';
import getCurrencySymbol from '../Statementofwork/getCurrencySymbol';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import { pl, enGB } from "date-fns/locale";
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { display } from '@mui/system';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/styles';
import InvoiceEndpoints from '../Invoices/Invoices.endpoints';
import Tooltip from "../../components/Tooltip/Tooltip";

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", 
    margin: "0px 0px 0px 230px",
     backgroundColor: "white" },
    dialogcontainer: { padding: '15px' }
}

const iconDivStyles =
{
    display: 'flex',
    cursor: "pointer",
}



const useStyles = makeStyles({
    root: {
       color:"red"
    },
    columnEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        cursor: "pointer"
    },
    tooltipTop: {
        "&::before": {
            borderTopColor: "rgba(97, 97, 97, 0.92) !important",
        },
        fontSize: "10px",
    },
})
const Reports = () => {
    const classes = useStyles()
    const loginData = useLocalStorage.getItem("loginData")
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [clickedRow, setClickedRow]: any = useState();
    const ApiService = useApiService();
    const [openFilter, setOpenFilter] = useState(false)
    const [customers, setCustomers]: any = useState([]);
    const [departments, setDepartments]: any = useState([])
    const [customerId, setCustomerId]: any = useState([])
    const [department_share, setDepartment_share]: any[] = useState([])
    const [salesperson_share, setSalesperson_share]: any[] = useState([])
    const [departmentSubmission, setDepartmentSubmission] = useState(false)
    const [openCalender, setOpenCalender]: any = useState(false)
    const [clientSowData, setClientSowData]: any = useState([])
    const [pendingPayment, setPendingPayment]: any = useState([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [startDate, setStartDate]: any = useState()
    const [endDate, setEndDate]: any = useState()
    const [Reset, setReset] = useState(false)
    const [filtersApplied, setFiltersApplied]:any = useState(false)
    const [accordionOpen, setAccordionOpen]:any = useState(false)
    const open = Boolean(anchorEl);
    const [state, setState] = useState([
        {
            startDate:
                subDays(new Date(), 30),
            // new Date(),
            endDate:
                //   addDays(new Date(), 1),
                new Date(),
            key: "selection"
        }
    ]);
    const defaultColumns = [
        { name: 'Customer Name', checked: true },
        { name: 'Project Name', checked: true },
        { name: 'Invoice Date', checked: true },
        { name: 'Credit Month', checked: true },
        { name: 'Invoice Amount', checked: true },
     ]
    
    const [filterCheckboxs, setFilterCheckboxs]: any = useState(defaultColumns);
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

 useEffect(() => {
    if (useLocalStorage.getItem('Reports-checkbox')) {
        setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('Reports-checkbox')))
    }
}, [])

const drawerClose = () => () => {
    setColumnFilterOpen(false);
    setFilterCheckboxs(defaultColumns)
    useLocalStorage.removeItem("Reports-checkbox")
};

    const list = (anchor: any) => (
        <Box
            sx={{ width: 250, margin: "60px 0px 0px 20px" }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                  <div style={{display:"flex" , justifyContent:"space-between" , margin:"10px 17px 10px 0px" , alignItems:"center"}}>
            
                <h2>Column Filters</h2>
                <Close style={{cursor:"pointer"}} onClick={()=>{
    if (useLocalStorage.getItem('Reports-checkbox')) {
    setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('Reports-checkbox')))
    setColumnFilterOpen(false)
    }
    else {
    setFilterCheckboxs(defaultColumns)
    setColumnFilterOpen(false)
    }
            }}/>
            </div>
    
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {filterCheckboxs?.map((item: any, index: number) => (
                        <div>
                            <FormControlLabel
                                key={item.name}
                                label={item.name}
                                control={
                                    <Checkbox
                                        {...register(`checkboxes.${index}`)}
                                        disableRipple={true}
                                        disableTouchRipple={true}
                                        style={{
                                            padding: "10px 10px"
                                        }}
                                        checked={item.checked}
                                        onChange={() => handleCardFilterValueChange(item.name)}
                                    />
                                }
                            />
                        </div>
                    ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "5px", margin: "20px 0px 0px 0px" }}>
    
                    <Btn
                        variant='outlined'
                        type="button"
                        btnText='Reset'
                        onClick={drawerClose()}
                    />
                    <Btn
                        variant='contained'
                        type="button"
                        btnText='Apply'
                        onClick={handleFilteration}
                    />
                </div>
    
    
            </List>
        </Box>
    );
    const toggleDrawer =
    (anchor: any, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
    
            setDrawerState({ ...drawerState, [anchor]: open });
        };
    const handleCardFilterValueChange = (name: any) => {
        // handle checkbox value changes
        setFilterCheckboxs((prevState: any) => {
            console.log(prevState, "====prevsstate")
            // if "All" clicked and checked uncheck everything else check everything.
            const modifiedPrevState = prevState.map((item: any, index: any) => {
                if (item.name === name) {
                    // setValue(`checkboxes.${index}`,{ name:item.name, checked: !item.checked })
                    return { name: item.name, checked: !item.checked };
                } else {
                    // setValue(`checkboxes.${index}`,{ name:item.name, checked: item.checked })
                    return { name: item.name, checked: item.checked };
                }
            });
            console.log(modifiedPrevState, "----modifiedddd")
            // console.log(getValues('checkboxes'),"======================data on apply=======================")
            return modifiedPrevState
    
        })
    }
    const handleFilteration = () => {
        // console.log(getValues('checkboxes'),"======================data on apply=======================")
        useLocalStorage.setItem('Reports-checkbox', JSON.stringify(filterCheckboxs))
        console.log(filterCheckboxs, "===========this is what we want=====")
        setColumnFilterOpen(false)

    }


    const {
        register,
        handleSubmit,
        control,
        formState: { errors, },
        getValues, setValue,
        reset
    } = useForm();

    const { fields: departmentFields, append: departmentAppend, remove: departmentRemove } = useFieldArray({ name: "department", control });
    const { fields: salespersonFields, append: salespersonAppend, remove: salespersonRemove } = useFieldArray({ name: "salesperson", control });

    const [autoCompleteValues, setAutocompleteValues]: any = useState
        ({
            customers: [],
            departments: [],
        })

    const getDropdownsData = async () => {
        let tempCustomers: any = [];
        let tempDepartments: any = [];
        const reqBody = {}
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };



        await ApiService.get(Statementofworkendpoints.sow_customer(reqData))
            .then((res: any) => {
                tempCustomers = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        ////////////////get all the sow information////////
        // getSOWs();
        await ApiService.get(DepartmentEndpoints.department(reqData))
            .then((res: any) => {
                tempDepartments = res.data;
            }).catch((err: any) => {
                showToast("ERROR", err.message)
            }).finally(() => {

            })
        console.log("----------DROPDOWN DATA----------")

        // console.log(tempProjects);
        // console.log(tempCustomers);
        // setAllProjects(tempProjects);
        // setProjects(tempProjects);
        setCustomers(tempCustomers);
        setDepartments(tempDepartments)
    }
    useEffect(() => {
        getDropdownsData()
    }, [])


    const getDepartmentsAndSalesperson = (data: any) => {
        setDepartment_share([])
        setSalesperson_share([])
        departmentRemove()
        salespersonRemove()
        if (data) {
            var tempSalesperson: any[] = []
            var temp: any = {}
            data?.map((d: any, index: number) => {
                console.log(d)
                let tempDepartment: any[] = []
                d?.sales_share?.map((sales: any) => {
                    let sale_string = `sales_${sales.sales_person_id}`
                    let amount_string = `invoice_${d.invoice_id}_amt`
                    if (temp.hasOwnProperty(sale_string)) {
                        temp[sale_string].name = sales.name;
                        if (!temp[sale_string].hasOwnProperty(amount_string)) {
                            temp[sale_string][amount_string] = sales.amount
                        }
                    }

                    else {
                        temp[sale_string] = {}
                        temp[sale_string].name = sales.name;
                        temp[sale_string].invoice_id = d.invoice_id;
                        temp[sale_string][amount_string] = sales.amount
                    }
                    // tempSalesperson.push({...sales, invoice_id:d.invoice_id})

                })

            })
            console.log(temp, "======================================================")
            for (let i in temp) {
                tempSalesperson.push(temp[i])
            }
            setValue("salesperson_share", tempSalesperson)
        }

    }
    const handleSales = (row: any, sales: any) => {
        console.log(row, "=====row")
        //    const temp =getValues("salesperson_share").map((sales:any)=>{
        if (sales.hasOwnProperty(`invoice_${row.invoice_id}_amt`)) {
            return getCurrencySymbol(row['billing_currency']) + ' ' + sales[`invoice_${sales.invoice_id}_amt`] ? getCurrencySymbol(row['billing_currency']) + ' ' + sales[`invoice_${row.invoice_id}_amt`]?.toLocaleString('en-IN') : " - "
        } else {
            return "-"
        }

    }

    const newColumns = () => {
        console.log("calledddddddd")
        getValues("salesperson_share")?.map((sales: any,) => {
            return (
                columns.push({
                    name: sales.name,
                    wrap: true,
                    minWidth: "125px",
                    cell: (row: any,) => handleSales(row, sales)
                    // getCurrencySymbol(row['billing_currency']) + ' ' + s.amount?  getCurrencySymbol(row['billing_currency']) + ' ' + s.amount : " - "

                })
            )
        })
    }

    const getReports = async (data?: any) => {

        const reqBody: any = {}
        const header = {
            access_token: loginData?.access_token
        }

        if (data == 'filters') {
            if(!getValues('end_date')){
                setValue('end_date',new Date())
            }
            if (new Date(getValues("end_date")) < new Date(getValues('start_date'))) {
                showToast("ERROR", "End date should be greater than start date")
                return;
            } else {
                reqBody.start_date = getValues("start_date") ? getValues("start_date") : moment(new Date()).subtract(1, 'months')
                reqBody.end_date = getValues('end_date') ? getValues('end_date') : moment(new Date()).subtract(1, 'months')
                setReset(true)
            }

            if ((getValues('customers')?.length || getValues("departments")?.length)) {
                let tempCustomerIdArr: any[] = []
                let departmentIdArr: any[] = []
                getValues('customers')?.map((custom: any) => {
                    tempCustomerIdArr.push(custom.customer_id)
                })
                getValues("departments")?.map((depart: any) => {
                    departmentIdArr.push(depart.department_id)
                })
                reqBody.customer_id = tempCustomerIdArr
                reqBody.department_id = departmentIdArr

            }
        } else if ( data =='submit'){
if((getValues('customers')?.length || getValues("departments")?.length)){
    let tempCustomerIdArr: any[] = []
    let departmentIdArr: any[] = []
    getValues('customers')?.map((custom: any) => {
        tempCustomerIdArr.push(custom.customer_id)
    })
    getValues("departments")?.map((depart: any) => {
        departmentIdArr.push(depart.department_id)
    })
    reqBody.customer_id = tempCustomerIdArr
    reqBody.department_id = departmentIdArr

    if (new Date(getValues("end_date")) < new Date(getValues('start_date'))) {
        showToast("ERROR", "End date should be greater than start date")
        return;
    } else {
        reqBody.start_date = getValues("start_date") ? getValues("start_date") : moment(new Date()).subtract(1, 'months')
        reqBody.end_date = getValues('end_date') ? getValues('end_date') : moment(new Date()).subtract(1, 'months')
        setReset(true)
    }
}
        }

        setLoading(true);
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(ReportEndpoints.reports(reqData))
            .then((res: any) => {
                console.log("----response data-----")
                setOpenFilter(false)
                setClientSowData(res.data)
                getDepartmentsAndSalesperson(res.data)

            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const showTooltip = (data: any) => {
        if (data && data.length > 11) {
            return (
                <Tooltip
                    content={data}
                    direction={"top"}
                    style={{
                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                        // marginTop: "-40px",
                        // marginLeft: "65px"
                    }}
                    wrapperStyle={{
                        width: "115px",
                    }}
                >
                    <span className={classes.columnEllipsis}>{data}</span>
                </Tooltip>
            );
        } else {
            return <span>{data}</span>;
        }
    };

    const columns: any = [
        {
            name: "Customer Name",
            selector: "customer_name",
            wrap: true,
            omit: useLocalStorage.getItem("Reports-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Reports-checkbox'))?.[0]?.checked == false : false,
            
            minWidth: "150px",
            cell: (row: any) => showTooltip(row["customer_name"])

        },
        {
            name: "Project Name",
            selector: "project_name",
            wrap: true,
            omit: useLocalStorage.getItem("Reports-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Reports-checkbox'))?.[1]?.checked == false : false,
            
            minWidth: "125px",
            cell: (row: any) => showTooltip(row["project_name"])
    
        },

        {
            name: "Invoice Date",
            selector: "invoice_date",
            omit: useLocalStorage.getItem("Reports-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Reports-checkbox'))?.[2]?.checked == false : false,
            
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => {
                return moment(row['invoice_date']).format('LL')
            }
        },
        {
            name: "Credit Month",
            selector: "credit_month",
            omit: useLocalStorage.getItem("Reports-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Reports-checkbox'))?.[3]?.checked == false : false,
            
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => {
                return moment(row['credit_month']).format("MMMM")
            }
        },
        {
            name: "Invoice Amount",
            selector: "amount",
            omit: useLocalStorage.getItem("Reports-checkbox") ?
                JSON.parse(useLocalStorage.getItem('Reports-checkbox'))?.[4]?.checked == false : false,
            
            wrap: true,
            minWidth: "125px",
            cell: (row: any) => {
                return getCurrencySymbol(row['billing_currency']) + ' ' + (row['invoice_amount']?.toLocaleString('en-IN'))
            }
        },

    ];

    const getStringData = (data: any) => {
        return data.map((val: any) => isNaN(val) ? JSON.stringify(val) : +val).join(',') + "\n"

        // let str = '';
        // str = JSON.stringify(data)
        // return str 
    }

    function convertArrayOfObjectsToCSV(array: any, delimiter = ',') {
        console.log(array, "===========arrrayyy==========")
        let result: any;

        array?.map((value: any) => {
            value.departments_share = getStringData(value.departments_share);
            value.sales_share = getStringData(value.sales_share);
        })


        console.log(array, "=========++++++========");

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(clientSowData[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item: any) => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }
    function downloadCSV(array: any) {
        const link = document.createElement('a');
        let csv = toCsv(pivot(clientSowData));
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }


    function pivot(arr: any) {
        var mp: any = new Map();

        function setValue(a: any, path: any, val: any) {
            if (Object(val) !== val) { // primitive value

                var pathStr = path.join('.');
                var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
                a[i] = val;
            } else {
                for (var key in val) {
                    // setValue(a,key=='departments_share' ? path : path.concat(`department${key}`),val[key])
                    setValue(a, key == '0' ? path : path.concat(key), val[key]);
                }
            }
            return a;
        }

        var result = arr.map((obj: any) => setValue([], [], obj));
        return [[...mp.keys()], ...result];
    }

    const toCsv = (arr: any) => {
        return arr.map((row: any) =>
            row.map((val: any) => isNaN(val) ? JSON.stringify(val) : +val).join(',')
        ).join('\n');
    }
    // console.log(toCsv(pivot(clientSowData)));




    useEffect(() => {
        setSalesperson_share([])
        setDepartment_share([])
        getReports()

        // getData();
    }, [])

    const handleClose = () => {

        setOpenFilter(false);
        departmentRemove()
        salespersonRemove()
        // // setValue("departmentsData",null)
        // // setValue("salespersonData",null)
        // setValue("department",null)
        // setValue("salesperson",null)
        // setValue("differenceAmount",null)
        // setAutocompleteValues({
        //     customers: [],
        //     departments: []
        // })

    };
    const handleDepartments = (departments: any) => {
        console.log(departments, "=======ddddddd====")
        if (departments) {
            departments.map((depart: any) => {
                departmentAppend({

                    dep_inv_id: depart.dep_inv_id,
                    department_id: depart.department_id,
                    department_share: depart.department_share,
                    department_name: depart.department_name,
                    department_amount: depart.department_amount

                })
            })

        }

    }
    const handleSalesperson = (salesperson: any) => {
        console.log(salesperson, "=======sssssssss====")
        if (salesperson) {
            salesperson.map((sales: any) => {
                salespersonAppend({
                    sales_person_id: sales?.sales_person_id,
                    sales_invoice_id: sales?.sales_invoice_id,
                    sales_person_share: parseInt(sales?.share),
                    salesperson_name: sales?.name,
                    sales_person_amount: sales?.amount
                })
            })

        }

    }
    const ExpandedComponent = (suppData: any) => ({ data }: any, index: number) => {
        console.log(data, "=================data==================================")
        setValue(`department_share${index}`, data?.departments_share)
        setValue(`salesperson_share${index}`, data?.sales_share)
        console.log(data)
        return (
            <>
                <div style={{ margin: "20px 0px", display: "flex", justifyContent: "center", fontSize: "18px", fontWeight: "800" }}>Total Amount- {getCurrencySymbol(data['billing_currency']) + ' ' + data?.invoice_amount?.toLocaleString('en-IN')}</div>

                <div style={{ padding: "0px", width: "100%", display: "inline-block", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
                    {data?.departments_share?.map((depart: any, index: number) => {

                        return (
                            // ------------------departmentsss-------------------------
                            <div className='item'>
                                {/* <div style={{ fontSize: "16px", fontWeight: "700" }}>Department {index + 1} </div> */}
                                <div style={{ fontSize: "18px", fontWeight: "500" }}>{depart.department_name}</div>

                                <div style={{ fontSize: "17px", fontWeight: "500" }}>Amount- {getCurrencySymbol(data['billing_currency']) + ' ' + depart?.department_amount?.toLocaleString('en-IN')}</div>
                            </div>
                        )
                    })}




                    <div>
                        <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {


                                handleDepartments(data?.departments_share)
                                handleSalesperson(data?.sales_share)
                                setValue("billing_currency", data.billing_currency)
                                setValue("invoice_id", data.invoice_id)
                                setValue("amount", data.invoice_amount)
                                console.log(data?.departments_share)
                                setValue("departmentsData", data?.departments_share)
                                setValue("salespersonData", data?.sales_share)
                                setValue("differenceAmount", data.unallocated_amount ? parseInt(data.unallocated_amount) : 0)
                                setOpenFilter(true)
                            }} />
                    </div>
                </div>
            </>
        );
    };
    const handleDifference = () => {

        let tempDepartment = [...getValues("department")]
        let tempSalesPerson = [...getValues("salesperson")]
        let totalAmount = getValues("amount")
        let totalDepartmentAmount: number = 0
        let totalSalespersonAmount: number = 0
        let DepartmentArr: any[] = []
        let SalespersonArr: any[] = []
        tempDepartment.map((depart: any, index: number) => {
            totalDepartmentAmount += parseInt(depart.department_amount)
            const { department_name, ...rest }: any = tempDepartment[index]
            DepartmentArr.push(rest)
        })
        tempSalesPerson.map((sales: any, index: number) => {
            totalSalespersonAmount += parseInt(sales.sales_person_amount)
            const { salesperson_name, ...rest }: any = tempSalesPerson[index]
            SalespersonArr.push(rest)
        })
        console.log(DepartmentArr, SalespersonArr, "==========================================final value=======================")
        console.log(totalDepartmentAmount, totalSalespersonAmount, "================departmetn and salesamount")
        let difference: number = 0
        difference = parseInt(totalAmount) - ((totalDepartmentAmount) + (totalSalespersonAmount))
        console.log(difference, "========================diffff====================")
        setValue("differenceAmount", difference)
        if (difference < 0) {
            showToast("ERROR", "Difference cannot be less than 0.")
            return;
        }
    }

    const onDepartmentSubmit = async (data: any) => {
        console.log(data, "===============data")


        let tempDepartment = data?.department
        let tempSalesPerson = data?.salesperson
        let totalDepartmentAmount: number = 0
        let totalSalespersonAmount: number = 0
        let DepartmentArr: any[] = []
        let SalespersonArr: any[] = []
        tempDepartment.map((depart: any, index: number) => {
            if (!depart.department_amount) {
                getValues('departmentsData')?.map((depart: any) => {
                    // setValue(`department.${index}.department_name` , depart?.department_name )
                    setValue(`department.${index}.department_amount`, depart.department_amount)

                })
                totalDepartmentAmount += parseInt(depart.department_amount)
                const { department_name, ...rest }: any = tempDepartment[index]
                DepartmentArr.push({ ...rest, department_amount: getValues(`department.${index}.department_amount`) })

            } else {
                totalDepartmentAmount += parseInt(depart.department_amount)
                const { department_name, ...rest }: any = tempDepartment[index]
                DepartmentArr.push(rest)

            }
        })
        tempSalesPerson.map((sales: any, index: number) => {
            totalSalespersonAmount += parseInt(sales.sales_person_amount)
            const { salesperson_name, ...rest }: any = tempSalesPerson[index]
            SalespersonArr.push(rest)
        })
        console.log(DepartmentArr, SalespersonArr, "==========================================final value=======================")
        console.log(totalDepartmentAmount, totalSalespersonAmount, "================departmetn and salesamount")
        if ((totalDepartmentAmount + totalSalespersonAmount > parseInt(getValues("amount")))) {
            showToast("ERROR", "Entered amount should not be more than total amount")
            return;
        }
        const reqBody = {
            department_share: DepartmentArr,
            sales_person_share: SalespersonArr,
            invoice_id: getValues("invoice_id"),
            amount: getValues("amount"),
            unallocated_amount: parseInt(getValues("differenceAmount"))

        }
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        setDepartmentSubmission(true)
        await ApiService.put(InvoiceEndpoints.invoice(reqData))
            .then((res: any) => {
                console.log(res.data)
                setOpenFilter(false)
                showToast("SUCCESS", res?.message)
                setOpenFilter(false)

                getReports('submit')

            })
            .catch((err: any) => {
                showToast("ERROR", err.message)
            })
            .finally(() => {
                setDepartmentSubmission(false)
            })
    }


    newColumns()


    return <React.Fragment>
        <Loader loading={loading}>
            <Container maxWidth={false} style={styles.container} className='Reports'>
                <Grid container spacing={0}>
                    <Grid item lg={12} md={12} style={{ display: 'flex', justifyContent: "space-between" }}>

                        <Grid style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: "0 auto" }}>
                            <h2>Reports</h2>
                        </Grid>
                        <Grid style={{ display: 'flex', justifyContent: "flex-end", flexDirection: "column", alignItems: "center" }}>
                            Download CSV
                            <DownloadOutlined
                                style={{ fontSize: "40px", cursor: "pointer" }}
                                onClick={() => downloadCSV(clientSowData)} />

                        </Grid>
                        <Grid style={{ display: 'flex', justifyContent: "flex-end", flexDirection: "column", alignItems: "center" }}>
                        <Tooltip
                                    content={'Column Filters'}
                                    direction={"top"}
                                    style={{
                                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                                        marginTop: "-20px",
                                        marginLeft:"0px",
                                    }}
                                    wrapperStyle={{
                                        width: "-webkit-fill-available",
                                    }}
                                    hideOnClick={true}
                                    className={classes.tooltipTop}
                                >
                                    <div className="jobList__notification">
                                        <div
                                            className="filterButton"
                                            style={{ transform: "none" , display:"flex" , justifyContent:"center", cursor:"pointer" , border:"1px solid black" }}
                                            onClick={() => setColumnFilterOpen(!columnFilterOpen)}
                                        >
                                            <FilterAlt style={{color:"black"}} />
                                        </div>
                                        <Drawer
                                            anchor={"right"}
                                            open={columnFilterOpen}
                                            onClose={() => {
                                                if (useLocalStorage.getItem('Reports-checkbox')) {
                                                    setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('Reports-checkbox')))
                                                    setColumnFilterOpen(false)
                                                }
                                                else {
                                                    setFilterCheckboxs(defaultColumns)
                                                    setColumnFilterOpen(false)
                                                }
                                            }
                                            }
                                        >
                                            {list('right')}
                                            {/* {columnListFilter()} */}
                                        </Drawer>
                                    </div>
                                </Tooltip>
                        </Grid>
                    </Grid>



                    {/* ====================filtersss============================ */}
                    <Accordion 
                    style={{ width:"100%" , margin:"20px 0px"}}
                    expanded={accordionOpen}
                    onChange={()=>setAccordionOpen(!accordionOpen)}
                    >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{display:"flex", justifyContent:"flex-end"}}
        >
          <Typography style={{display:"flex" , alignItems:"center", gap:"10px"}}>Filters <span className='dot' style={filtersApplied? {backgroundColor:"green"} : {backgroundColor:"red"}}></span> </Typography>
        </AccordionSummary>
        <AccordionDetails>
                    <Grid container spacing={2} style={{ width:"100%" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            {/* <Box m={2}> */}

                            <Grid xs={6} md={6} lg={6} style={{width:"100%" , padding:"16px 0px 0px 16px"}}>
                                <DatePicker
                                
                                    inputFormat="MMM, yy"
                                    views={['month', 'year']}
                                    label="Start month"
                                    //   minDate={}
                                    //   maxDate={endDate? endDate : new Date()}
                                    value={startDate ? startDate : 
                                        <>
                                        {setStartDate(moment(new Date()).subtract(1, 'months'))}
                                        {setValue('start_date' ,moment(new Date()).subtract(1, 'months'))}
                                        </>
                                    }

                                    InputProps={{
                                        style: {
                                            borderRadius: "25px",
                                        }
                                    }}
                                   PaperProps={{
                                        style: {
                                            borderRadius: "25px",
                                            marginLeft: "50px"
                          }
                                    }}
                                    onChange={(e: any) => {

                                        setValue("start_date", e)

                                        console.log(new Date(e).getMonth())
                                        setStartDate(e)
                                    }
                                    }
                                    renderInput={(params:any) => <TextField {...params} helperText={null} fullWidth />}
                                />
                            </Grid>
                            <Grid xs={6} md={6} lg={6} style={{width:"100%" , padding:"16px 0px 0px 16px"}}>
                                <DatePicker
                                    inputFormat="MMM, yy"
                                    views={['month', 'year']}
                                    label="End Month"
                                    InputProps={{
                                        style: {
                                            borderRadius: "25px"
                                        }
                                    }}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "25px",
                                            marginLeft: "50px"
                                        }
                                    }}
                                    // minDate={startDate ? startDate : new Date()}
                                    //   maxDate={new Date('2023-06-01')}
                                    value={endDate ? endDate : <>
                                    {setEndDate(moment(new Date()).subtract(1, 'months'))}
                                    {setValue('end_date',moment(new Date()).subtract(1, 'months'))}
                                    </>
                                        // setValue("end_date",moment(new Date()).subtract(1, 'months'))
                                    }
                                    onChange={(e: any) => {
                                        setValue("end_date", e)
                                        setEndDate(e)
                                    }
                                    }
                                    renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
                                />
                            </Grid>
                            {/* </Box> */}
                        </LocalizationProvider>
                        
                        <Grid item lg={6} md={6}>
                            <Controller
                                control={control}
                                name={`customers`}
                                render={({ field: any }) => (
                                    <Autocomplete
                                        multiple={true}

                                        menuItems={customers}
                                        // disabled={operation === 'view'}
                                        valueKey='customer_id'
                                        showNameKey='name'
                                        getOptionSelected={(option: any, value: any) => option.customer_id === value.customer_id}
                                        value={autoCompleteValues.customers}
                                        label='Select Customer'

                                        placeholder='add customer'
                                        onChange={(e: any, value: any) => {
                                            setValue("customers", value);
                                            // handleCustomerFilter()
                                            setAutocompleteValues({ ...autoCompleteValues, customers: value })
                                        }}
                                    />
                                )}
                            />

                        </Grid>
                        {/* <Typography>Department Filters -</Typography> */}
                        <Grid item xs={6} md={6} lg={6}>
                            <Controller
                                control={control}
                                name={`departments`}
                                render={({ field: any }) => (
                                    <Autocomplete
                                        multiple={true}
                                        // disabled={operation === 'view'}
                                        label='Select Department'
                                        menuItems={departments}
                                        valueKey='department_id'
                                        showNameKey='name'
                                        getOptionSelected={(option: any, value: any) => option.department_id === value.department_id} value={autoCompleteValues.departments}
                                        onChange={(e: any, value: any) => {

                                            setValue(`departments`, value);
                                            setAutocompleteValues({ ...autoCompleteValues, departments: value })

                                        }}
                                    // error={errors?.department?.[index]?.department_id ? true : false}
                                    />
                                )}
                            />
                            {/* <Errormessage errormessage={errors?.department?.[index]?.department_id ? errors?.department?.[index]?.department_id?.message : ''} /> */}
                        </Grid>

                        <Grid xs={12} md={12} style={{ marginTop:"10px",display:"flex" , justifyContent:"flex-end" , gap:"10px" }}>
                            <Button 
                            variant='outlined'
                            type='button'
                                style={{ borderRadius: "25px", display: Reset ? "inline" : "none" , height:"fit-content" }}
                                onClick={() => {
                                    setStartDate(null)
                                    setEndDate(null)
                                    setAutocompleteValues({
                                        customers: [],
                                        departments: [],
                                    })
                                    setReset(false)
                                    setAccordionOpen(false)
                                    setFiltersApplied(false)
                                    getReports()
                                }}
                                btnText={'Reset'}
                            />

                            <Button 
                            type='button'
                            variant='contained'
                                style={{ borderRadius: "20px",height:"fit-content" }}
                                onClick={() => {
                                    getReports("filters")
                                    setAccordionOpen(true)
                                    setFiltersApplied(true)

                                }}
btnText={'Apply'}
                            />
                        </Grid>
                    </Grid>


                    </AccordionDetails>
                    </Accordion>





                    <Grid item lg={12} md={12}>
                        <Datatable
                            fixedHeader={true}
                            columns={columns}
                            data={clientSowData}
                            expandableRows={true}
                            expandOnRowClicked={true}
                            expandableRowsComponent={ExpandedComponent("HELLO")}
                            noDataComponent={!loading ? clientSowData?.length ? " " : " No records to display " : " "}


                        />
                    </Grid>
                </Grid>




                {/* ===================================filters dialog============================ */}
                {openFilter &&
                    <Dialog
                        open={openFilter}
                        maxWidth='lg'
                        // fullWidth={true}
                        className='filter-dialog'
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h2> {" Edit Allocation"}</h2>
                        </DialogTitle>
                        <form onSubmit={handleSubmit(onDepartmentSubmit)}>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Grid xs={12} md={12} lg={12}
                                        style={{
                                            display: "flex", justifyContent: "center", alignItems: "center"
                                            , margin: "10px 0px"
                                        }}
                                    ><h3>Total amount - {getCurrencySymbol(getValues('billing_currency')) + " " + getValues("amount")?.toLocaleString("en-IN")}</h3>
                                    </Grid>


                                    {/* ======================emtpy notation================================ */}
                                    <Grid xs={12} md={12} lg={12}>
                                        <Controller
                                            control={control}
                                            name={`differenceAmount`}
                                            render={({ field: any }) => (
                                                <FormControl focused className="col" variant='outlined' style={{ width: "100%", marginTop: "7px" }}>
                                                    <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey", padding: "0px 20px 0px 10px" }}>Unallocated Amount</InputLabel>
                                                    <NumberFormat
                                                        autoFocus={false}
                                                        // disabled={operation === 'view'}
                                                        required={true}
                                                        thousandsGroupStyle="lakh"
                                                        displayType='input'
                                                        value={getValues(`differenceAmount`) ? getValues(`differenceAmount`) : 0}
                                                        prefix={getValues("billing_currency") ? `${getCurrencySymbol(getValues('billing_currency'))} ` : ""}
                                                        allowNegative={true}
                                                        customInput={TextField}
                                                        thousandSeparator={true}
                                                        className='format'
                                                        InputProps={{

                                                            style: {
                                                                borderRadius: "25px",

                                                                // marginTop:"10px",
                                                            }

                                                        }}
                                                        autoComplete='off'
                                                        onValueChange={(values: any) => {
                                                            const { formattedValue, value } = values;
                                                            console.log(value, "==============val==============")
                                                            if (value.length) {
                                                                setValue(`differenceAmount`, value)
                                                            } else {
                                                                setValue(`differenceAmount`, " ")
                                                            }


                                                        }}
                                                        error={errors.amount ? true : false}
                                                    />
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>




                                    <Grid xs={12} md={10} lg={10}
                                        style={{ display: "flex", justifyContent: "center", margin: "10px 0px", fontWeight: 600 }}
                                    > Departments</Grid>

                                    <Grid xs={12} md={12} lg={12}
                                        container
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {departmentFields?.map((depart: any, index: number) => {
                                            console.log(depart, "==========44444========")
                                            return (
                                                <>
                                                    <Input
                                                        required={false}
                                                        type="hidden"
                                                        label="department_id"
                                                        register={register(`department.${index}.department_id`)}
                                                        autoFocus={false}
                                                        // value={getValues(`department.${index}.department_id`) ? getValues(`department.${index}.department_id`) : setValue(`department.${index}.department_id`, depart.department_id)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Input
                                                        required={false}
                                                        type="hidden"
                                                        label="sales_invoice_id"
                                                        register={register(`department.${index}.dep_inv_id`)}
                                                        autoFocus={false}
                                                        // value={getValues(`department.${index}.dep_inv_id`) ? getValues(`department.${index}.dep_inv_id`) : setValue(`department.${index}.dep_inv_id`, depart.dep_inv_id)}
                                                        style={{ display: 'none' }}
                                                    />

                                                    <Input
                                                        required={false}
                                                        type="hidden"
                                                        label="department_share"
                                                        register={register(`department.${index}.department_share`)}
                                                        autoFocus={false}
                                                        // value={getValues(`department.${index}.department_share`) ? getValues(`department.${index}.department_share`) : setValue(`department.${index}.department_share`, depart.department_share)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Grid xs={5} md={5} lg={5}
                                                        style={{ display: "flex", justifyContent: "center" }}

                                                    >

                                                        <Input
                                                            style={{ borderRadius: "25px", }}
                                                            disabled={true}
                                                            type="text"
                                                            label={`Department ${index + 1}`}
                                                            defaultValue={getValues(`department.${index}.department_name`)}
                                                            register={register(`department_name${index}`)}
                                                            autoFocus={false}
                                                            error={errors.title ? true : false}
                                                        />
                                                    </Grid>

                                                    <Grid xs={5} md={5} lg={5}>
                                                        <Controller
                                                            control={control}
                                                            name={`department.${index}.department_amount`}
                                                            render={({ field: any }) => (
                                                                <FormControl focused className="col" variant='outlined' style={{ width: "100%", marginTop: "7px" }}>
                                                                    <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey", padding: "0px 20px 0px 10px" }}>Amount</InputLabel>

                                                                    <NumberFormat
                                                                        autoFocus={false}
                                                                        // disabled={operation === 'view'}
                                                                        required={true}
                                                                        thousandsGroupStyle="lakh"
                                                                        displayType='input'
                                                                        value={getValues(`department.${index}.department_amount`)}
                                                                        prefix={getValues("billing_currency") ? `${getCurrencySymbol(getValues('billing_currency'))} ` : ""}
                                                                        allowNegative={false}
                                                                        customInput={TextField}
                                                                        thousandSeparator={true}
                                                                        className='format'
                                                                        InputProps={{

                                                                            style: {
                                                                                borderRadius: "25px",

                                                                                // marginTop:"10px",
                                                                            }

                                                                        }}
                                                                        autoComplete='off'
                                                                        onValueChange={(values: any) => {
                                                                            const { formattedValue, value } = values
                                                                            setValue(`department.${index}.department_amount`, value)
                                                                            handleDifference()


                                                                            handleDifference()

                                                                        }}
                                                                        error={errors.amount ? true : false}
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                                    <Grid xs={12} md={10} lg={10}
                                        style={{ display: "flex", justifyContent: "center", margin: "10px 0px", fontWeight: 600 }}

                                    > Salespersons</Grid>
                                    <Loader loading={departmentSubmission} />

                                    <Grid xs={12} md={12} lg={12}
                                        container
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {salespersonFields?.map((sales: any, index: number) => {
                                            return (
                                                <>
                                                    <Input
                                                        required={false}
                                                        type="hidden"
                                                        label="sales_person_id"
                                                        register={register(`salesperson.${index}.sales_person_id`)}
                                                        autoFocus={false}
                                                        // value={getValues(`salesperson.${index}.sales_person_id`)?getValues(`salesperson.${index}.sales_person_id`):setValue(`salesperson.${index}.sales_person_id`,sales.sales_person_id)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Input
                                                        required={false}
                                                        type="hidden"
                                                        label="sales_invoice_id"
                                                        register={register(`salesperson.${index}.sales_invoice_id`)}
                                                        autoFocus={false}
                                                        // value={getValues(`salesperson.${index}.sales_invoice_id`)?getValues(`salesperson.${index}.sales_invoice_id`):setValue(`salesperson.${index}.sales_invoice_id`,sales.sales_invoice_id)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Input
                                                        required={false}
                                                        type="hidden"
                                                        label="sales_person_share"
                                                        register={register(`salesperson.${index}.sales_person_share`)}
                                                        autoFocus={false}
                                                        // value={getValues(`salesperson.${index}.sales_person_share`)?getValues(`salesperson.${index}.sales_person_share`):setValue(`salesperson.${index}.sales_person_share`,sales.share)}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Grid xs={5} md={5} lg={5}
                                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                                    >
                                                        <Input
                                                            style={{ borderRadius: "25px", }}
                                                            disabled={true}
                                                            type="text"
                                                            label={`Salesperson ${index + 1}`}
                                                            defaultValue={getValues(`salesperson.${index}.salesperson_name`)}
                                                            register={register(`salesperson_name${index}.salesperson_name`)}
                                                            autoFocus={false}
                                                            error={errors.title ? true : false}
                                                        />
                                                    </Grid>
                                                    <Grid xs={5} md={5} lg={5}
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name={`salesperson.${index}.sales_person_amount`}
                                                            render={({ field: any }) => (
                                                                <FormControl focused className="col" variant='outlined' style={{ width: "100%", marginTop: "7px" }}>
                                                                    <InputLabel className='mText' style={{ backgroundColor: "white", color: "grey", padding: "0px 20px 0px 10px" }}>Amount</InputLabel>
                                                                    <NumberFormat
                                                                        autoFocus={false}
                                                                        // disabled={operation === 'view'}
                                                                        required={true}
                                                                        thousandsGroupStyle="lakh"
                                                                        displayType='input'
                                                                        value={getValues(`salesperson.${index}.sales_person_amount`)}
                                                                        prefix={getCurrencySymbol(getValues("billing_currency")) ? `${getCurrencySymbol(getValues('billing_currency'))} ` : ""}
                                                                        allowNegative={false}
                                                                        customInput={TextField}
                                                                        thousandSeparator={true}
                                                                        className='format'
                                                                        InputProps={{

                                                                            style: {
                                                                                borderRadius: "25px",

                                                                                // marginTop:"10px",
                                                                            }

                                                                        }}
                                                                        autoComplete='off'
                                                                        onValueChange={(values: any) => {
                                                                            const { formattedValue, value } = values;

                                                                            setValue(`salesperson.${index}.sales_person_amount`, value)
                                                                            handleDifference()

                                                                        }}
                                                                        error={errors.amount ? true : false}
                                                                    />
                                                                </FormControl>
                                                            )}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button 
                                variant='outlined'
                                    onClick={handleClose}
                                    btnText={'Cancel'} 
                                    type={'button'}/>
                                <Button 
                                variant='contained'
                                disable={departmentSubmission}
                                    style={{ backgroundColor: "#007ebf" }}
                                    type={"submit"}
                                    btnText='Save'
                                    />
                                   
                            </DialogActions>
                        </form>
                    </Dialog>
                }
            </Container>
        </Loader>
    </React.Fragment>
}

export default Reports;