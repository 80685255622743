import React, { useEffect, useState } from 'react';
import BackgroundImage from "../../../assets/images/invoices2.svg";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../../components/Input/Input';
import Errormessage from '../../../components/Errormessage/Errormessage';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import useApiService from '../../../services/api.service';
import Loader from '../../../components/Loader/Loader';
import ResetpasswordEndpoints from './Resetpassword.endpoints';
import { showToast } from '../../../components/Toast/Toast';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Btn from '../../../components/Button/Button';

const theme = createTheme();

const Resetpassword = () => {

  const [loading, setLoading] = useState(false);
  const ApiService = useApiService();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword,setShowConfirmPassword]=useState(false);

  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const access_token = searchParams.get("access_token");
  console.log(access_token);

//////////toggle show password function////////////
const handleClickShowPassword = () => setShowPassword(!showPassword)

//////////toggle show password function////////////
const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

  const onSubmit = (data: any) => {
    console.log("------------------FORM SUBMITTED--------------------");
    console.log(data);
    /////show loader////////
    setLoading(true);


  
    const reqBody: any =
    {
      new_password: data.password
    };

    const reqData = {
      reqBody: reqBody,
      headers: {
        access_token: access_token
      },
    };

    return ApiService.put(ResetpasswordEndpoints.resetPassword(reqData)).
      then((res: any) => {
        showToast('SUCCESS', res.message);
        navigate('/signin');
      }).catch((err: any) => {
        showToast('ERROR', err.message);
      }).finally(() => {
        setLoading(false);
      })
  };

  // ---------------validation schema-----------------
  const validationSchema = Yup.object().shape({
    password: Yup.string().trim()
      .required('This field is required')
      .max(20, 'Password must not exceed 20 characters')
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters,one uppercase,one number and one special case character"
      ),
    confirm_password: Yup.string().trim()
      .required('This field is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  return (
    <Loader loading={loading}>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={6}
            md={6}
            lg={6}
            sx={{
              backgroundImage: `url(${BackgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={6} sm={6} md={6} lg={6} component={Paper} elevation={6} square style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                borderRadius:"20px",
                padding:"3rem",
                width:"600px",
                height:"auto",
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <Grid container spacing={1}>
                  <Grid item md={12} lg={12}>
                    {/* <Input
                      required={true}
                      fullWidth={true}
                      register={register('password')}
                      label="Password"
                      type="password"
                      autoFocus={true}
                    /> */}
                     <Input
                      // required={true}
                      fullWidth={true}
                     register= {register('password')}
                      label="Password*"
                      type={showPassword ? "text" : "password"}
                      autoComplete='off'
                      error={errors.password ? true : false}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <Errormessage
                      errormessage={errors.password?.message}
                    />
                  </Grid>
                  <Grid item lg={12} md={12}>
                    {/* <Input
                      required={true}
                      fullWidth={true}
                      register={register('confirm_password')}
                      label="Confirm Password"
                      type="password"
                    /> */}
                     <Input
                      // required={true}
                      fullWidth={true}
                      register = {register('confirm_password')}
                      label="Confirm Password*"
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete='off'
                      error={errors.confirm_password ? true : false}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <Errormessage
                      errormessage={errors.confirm_password?.message}
                    />
                  </Grid>

                  <Grid item lg={5} md={5} style={{margin:"0 auto"}}>
                    <Btn
                      type="submit"
                      variant="contained" btnText={'Reset Password'}      /              >
                      
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Loader>
  );
}

export default Resetpassword;