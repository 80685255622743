import React from 'react'
import Sidebar from '../../Sidebar/Sidebar';

const Signup = () => {
  return (<React.Fragment>
    <h1>Signup</h1>
  </React.Fragment>
  )
}

export default Signup