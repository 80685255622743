import React, { useEffect, useState } from 'react';
import './App.scss';
import { Routes, Route, useNavigate, Navigate , useLocation } from 'react-router-dom';
import SignIn from './containers/Auth/login/Login';
import Forgotpassword from './containers/Auth/forgotpassword/Forgotpassword';
import Signup from './containers/Auth/signup/Signup';
import Resetpassword from './containers/Auth/resetpassword/Resetpassword';
import Notfound from './containers/Notfound-404/Notfound';
import Toast from './components/Toast/Toast';
import Users from './containers/Users/Users';
import useLocalStorage from './hooks/localStorage';
import Sidebar, { DrawerHeader } from './containers/Sidebar/Sidebar';
import AddStatementOfWork from './containers/Statementofwork/AddStatementofwork';
import Reports from './containers/Reports/Report';
import Salesperson from './containers/Salesperson/Salesperson';
import Customers from './containers/Customers/Customers';
import Projects from './containers/Projects/Projects';
import Departments from './containers/Departments/Department';
import StatementofWork from './containers/Statementofwork/StatementOfWork';
import Invoice from './containers/Invoices/Invoice';
import CreateInvoice from './containers/Invoices/CreateInvoices';
import DepartmentReport from './containers/Reports/DepartmentReport';
import CumulativeReports from './containers/Reports/CumulativeReports';
import { styled, useTheme } from '@mui/material/styles';

const exclusionArray = [
  "/signin",
  "/forgot-password",
  "/reset-password",
];
const drawerWidth = 230;

const Main = styled('main', { shouldForwardProp: (prop: any) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }: any) => ({
  flexGrow: 1,
  padding: exclusionArray.indexOf(useLocation().pathname) < 0 ?  theme.spacing(3) : 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
const App = () => {
  const [open, setOpen] = useState(true)
  const loginData = useLocalStorage.getItem("loginData")
  const location = useLocation();
  const navigate = useNavigate()

  const isHeaderSidemenuVisible = exclusionArray.indexOf(location.pathname) < 0;
  useEffect(() => {
    window.addEventListener("popstate", e => {
      // Nope, go back to your page
      const loginData =useLocalStorage.getItem('loginData');
      if(!loginData && window.location.pathname != '/reset-password'){
          navigate('/signin')
          window.location.reload()
      }
    });
  }, [window])
  console.log(useLocalStorage.getItem("open") , "-----open=----")  
  return <React.Fragment>
    {/* --------------SHOW HEADER IF PERSON IS LOGIN----------- */}

    {isHeaderSidemenuVisible?<Sidebar open={(data:any)=>setOpen(data)}/>:null}
    {/* <Main open={useLocalStorage.getItem("open")}>
      <DrawerHeader/> */}
       <Main open={open}>
        {isHeaderSidemenuVisible ?<DrawerHeader/> : null }
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<Forgotpassword />} />
      <Route path="/reset-password" element={<Resetpassword />} />
      <Route path="/signup" element={<Signup/>} />
      <Route path="/" element={<Navigate to="/signin" />} />
      <Route path="/users" element={<Users/>}/>
      <Route path="/statement-of-work" element={<StatementofWork/>}/>
      <Route path="/invoices" element={<Invoice/>}/>
      <Route path="/add/invoices" element={<CreateInvoice/>}/>
      <Route path="/reports" element={<Reports/>}/>
      <Route path="department-report" element={<DepartmentReport/>}/>
      <Route path="all-report" element={<CumulativeReports/>}/>
      <Route path="/salesperson" element={<Salesperson/>}/>
      <Route path="/customers" element={<Customers/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/departments" element={<Departments/>}/>
      <Route path="/add/statement-of-work" element={<AddStatementOfWork/>}/>
      <Route path="*" element={<Notfound />} />
     
    </Routes>
    </Main> 
    {/* </Main> */}
    {/* ---------TOAST--------- */}
    <Toast />
  </React.Fragment>
}

export default App;
