const CustomerEndpoints = {
    customer(reqData: any) {
      return {
        url: "sow/customer",
        data: reqData["reqBody"],
        headers: reqData["headers"],
      };
    }
  };
  
  export default CustomerEndpoints;