import React, { useEffect, useState } from 'react';
import BackgroundImage from "../../../assets/images/invoices2.svg";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../../../components/Input/Input';
import Errormessage from '../../../components/Errormessage/Errormessage';
import { Link } from 'react-router-dom';
import { showToast } from '../../../components/Toast/Toast';
import { Paper, TextField } from '@mui/material';
import useApiService from '../../../services/api.service';
import Loader from '../../../components/Loader/Loader';
import ForgotpasswordEndpoints from './Forgotpassword.endpoints';
import Btn from '../../../components/Button/Button';

const theme = createTheme();

const Forgotpassword = () => {
  const [loading, setLoading] = useState(false);
  const ApiService = useApiService();

  const onSubmit = async (data: any) => {
    console.log("------------------FORM SUBMITTED--------------------");
    console.log(data);
    /////show loader////////
    setLoading(true);

    const reqBody: any =
    {
      email: data.email
    };

    const reqData = {
      reqBody: reqBody,
      headers: {},
    };

    await ApiService.put(ForgotpasswordEndpoints.forgotPassword(reqData)).
      then((res: any) => {
        showToast('SUCCESS', res.message);
      }).catch((err: any) => {
        showToast('ERROR', err.message);
        /////////set loading to false/////////
      }).finally(() => {
        setLoading(false)
      });
  };

  // ---------------validation schema-----------------
  const validationSchema = Yup.object().shape({
    email: Yup.string().trim()
      .required('Email is required')
      .email('Email format is invalid')
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  return (
    <Loader loading={loading}>
      <ThemeProvider theme={theme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={6}
            md={6}
            lg={6}
            sx={{
              backgroundImage: `url(${BackgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid item xs={12} sm={6} md={6} lg={6} component={Paper} elevation={6} square style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                my: 8,
                mx: 4,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                borderRadius:"20px",
                padding:"3rem",
                width:"600px",
                height:"auto",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <Grid container spacing={1} style={{ minWidth: "540px" }}>
                  <Grid item md={12} lg={12}>
                    <Input
                      // required={true}
                      type="email"
                      fullWidth={true}
                      label="Email Address*"
                      register={register('email')}
                      autoComplete="on"
                      // autoFocus={true}
                      error={errors.email ? true : false}
                    />

                    {/* <TextField
                      required={true}
                      fullWidth={true}
                      {...register('email')}
                      label="Email"
                      type="email"
                      autoComplete='on'
                      error={errors.email ? true : false}
                    /> */}
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <Errormessage
                      errormessage={errors.email?.message}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} style={{margin:"0 auto"}}>
                    <Btn
                      type="submit"
                      variant="contained" btnText={'Send mail'}/>
                      
                   
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid style={{padding:"10px"}} item xs>
                    <Link to="/signin">
                      Signin?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Loader>
  );
}

export default Forgotpassword;