import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@material-ui/styles';
import { Checkbox, ListItemText, Select } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
const useStyles = makeStyles({
    select:{
        borderRadius:"25px !important",
    },
    paper: {
      borderRadius:"25px !important",
    },
    inputRoot:{
  borderRadius:"25px !important",
  // border:"1px solid grey"
    },
    label:{
      color:"black !important",
    }
  });
const DropDownComponent = (props: any) => {
    const { register, label, menuItems, value,placeholder, valueKey, showNameKey, disabled, onChange, error, defaultValue, showCreateOption, popupFunction, autoFocus  } = props;
    const classes = useStyles()

    console.log('typeof menuItems -> ', menuItems)

    return (
        <div style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
            <div style={{ pointerEvents: disabled ? 'none' : 'visible' }}>
                <Box sx={{ minWidth: 120 }}>
                    <FormControl variant='outlined' fullWidth  >
                        <InputLabel  className={classes.label} id="demo-controlled-open-select-label">{label}</InputLabel>
                        <Select
                            defaultValue={defaultValue}
                            disabled={disabled || false}
                            labelId="demo-controlled-open-select-label"
                            id="demo-simple-select"
                            label={label}
                           MenuProps={MenuProps}
                            placeholder={placeholder || null}
                            autoFocus={autoFocus || false}
                            onChange={onChange}
                            {...register}
                            value={value}
                            error={error}
                            className={classes.paper}
                        >
                          {console.log("menuitems")}

                            {showCreateOption && <MenuItem value={undefined} onClick={popupFunction}><AddCircleOutlineIcon /></MenuItem>}
                            {menuItems.length ?  menuItems.map((value1: any, index: number) => {
                                return <MenuItem key={value1[showNameKey]} value={value1[valueKey]}>{value1[showNameKey]}</MenuItem>
                            })
                          :
                          <MenuItem> No Options</MenuItem>
                          }
                        </Select>
                    </FormControl>
                </Box>
            </div>
        </div>
    );
}

export default DropDownComponent;