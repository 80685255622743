import { InputAdornment, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react';
import "./Input.scss";

const useStyles = makeStyles({
    input:{
        borderRadius:"25px !important",
    }
})
const Input = (props: any) => {
    const classes = useStyles()
    ///////////////////PROPS/////////////////////////////
    let { register, label, type, required, autoFocus,value, error, maxRows, onChange, defaultValue, style, disabled, isAmount, currency } = props;
    /////////////////////////////////////////////////////
    return <React.Fragment>
        <div style={{ cursor:disabled ? 'not-allowed' : 'pointer' }}>
            <div style={{ pointerEvents: disabled ? 'none' : 'visible' }}>
                <TextField
                    autoComplete='on'
                    disabled={disabled}
                    margin="normal"
                    placeholder={props.placeholder || null}
                    required={required || false}
                    type={type || 'text'}
                    fullWidth
                    defaultValue={defaultValue || null}
                    label={label}
                    multiline={maxRows ? true : false}
                    maxRows={maxRows}
                    {...register}
                    autoFocus={autoFocus || false}
                    error={error || false}
                    onWheel={(event:any) => { event.target.blur(); }}
                    onChange={onChange}
                    InputLabelProps={{ shrink: true , style:{color:"black"} }}
                    style={{ ...style }}
                
                    InputProps={{
                        ...props.InputProps,
                        className:classes.input,
                        startAdornment: currency || '',
                        inputProps:{ min : 0 ,color:"black" },                        
                    }}

                />
            </div>
        </div>
    </React.Fragment>
}

export default Input