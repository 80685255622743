import { Typography } from '@mui/material';
import React from 'react';
const Errormessage = (props: any) => {
    const {errormessage} = props;
    return <React.Fragment>
        <Typography variant="subtitle1" color="error" >
            {errormessage}
        </Typography>
    </React.Fragment>
}
export default Errormessage;