const InvoiceEndpoints = {
  sow(reqData: any) {
    return {
      url: "sow/sow",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  customer(reqData: any) {
    return {
      url: "sow/customer",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  milestone(reqData: any) {
    return {
      url: "sow/milestone",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  sow_salesperson(reqData: any) {
    return {
      url: "sow/salePerson",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  sow_department(reqData: any) {
    return {
      url: "sow/department",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  invoice(reqData: any) {
    return {
      url: "invoices/invoice",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  invoice_milestone (reqData:any) {
return {
  url:"invoices/getmilestone",
  data:reqData["reqBody"],
  headers:reqData['headers'],
}
  },
  invoice_pdf(reqData: any) {
    return {
      url: "invoices/invoice-pdf",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  invoices_salesperson(reqData: any) {
    return {
      url: "invoices/salesshare",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  invoices_department(reqData: any) {
    return {
      url: "invoices/departmentshare",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  
};
export default InvoiceEndpoints;