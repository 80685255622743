import React, { useImperativeHandle, forwardRef, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import Btn from "../Button/Button";
const AlertDialog: React.FC<any> = forwardRef((props: any, ref: any) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled]: any = useState(false);
  useImperativeHandle(ref, () =>
  ({
    handleClickOpen: () => {
      setOpenAlert(true);
    },
    handleClose: () => {
      setOpenAlert(false);
    }

  }));


  return (
    <div>
      <Dialog
        className='dialogAlertStyle'
        open={openAlert}
        onClose={props.onDiscard}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="dialog_title">
            {props.title ? props.title : null}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description ? props.description : null}
          </DialogContentText>
        </DialogContent>

        <DialogActions
          className="dialogactions"
          style={{ justifyContent: "center", paddingBottom: "24px", paddingTop: "13px", gap: "15px", cursor: "pointer" }}
        >

          {/* <Custombutton
            onClick={() => {
              props.onConfirm();
              // setIsButtonDisabled(false);
            }}
            type={"button"}
            styling={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        borderRadius: "20px",
                        opacity: 1,
                        color: "#000000",
                        padding: "5px 20px",
                        border: '1px solid #000000',
                        textTransform: 'none',
                        margin: '0px',
                        fontSize: '100%',
                        width: "auto",

           }}
            content="Confirm"
            disabled={isButtonDisabled}
          ></Custombutton> */}



          {/* <Custombutton
              onClick={props.onDiscard}
              color={"default"}
              type={"button"}
              styling={{
                background: '#000000 0% 0% no-repeat padding-box',
                borderRadius: "20px",
                opacity: 1,
                color: "#ffffff",
                padding: "6px 22.5px",
                textTransform: 'none',
                margin: '0px',
                fontSize: '100%',
                fontWeight: 'medium',
                width: "auto",

              }}
              content="Discard"
            /> */}

          <Btn variant="outlined"
          onClick={() => {
            props.onDiscard();
          } } btnText={"Discard"} type={"button"} />


          <Btn variant="contained"

          onClick={() => {
            props.onConfirm();
          } } btnText={"Confirm"} type={"button"}/>
        </DialogActions>
      </Dialog>

    </div>
  );
});

export default AlertDialog;
