import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Container, Divider, Drawer, FormControlLabel, Grid, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from "../../components/Multiselect/Multiselect"
import Datatable from '../../components/Datatable/Datatable';
import Loader from '../../components/Loader/Loader';
import './Statementofwork.scss';
// -------------------ICONS IMAGES--------------
import useApiService from '../../services/api.service';
import useLocalStorage from '../../hooks/localStorage';
import { showToast } from '../../components/Toast/Toast';
//---------------------------------------------------
import { Controller, useForm } from 'react-hook-form';
import Statementofworkendpoints from './Statementofwork.endpoints';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import getCurrencySymbol from './getCurrencySymbol';
import moment from 'moment';
// -------------------ICONS IMAGES--------------
import ViewIcon from "../../assets/images/eye.svg";
import EditIcon from "../../assets/images/editIcon.svg";
import DeleteIcon from "../../assets/images/deleteIcon.svg";
import AlertDialog from '../../components/Alertdialog/Dialog';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, Close, ExpandMoreOutlined, FilterAlt, FilterAltOffOutlined } from '@mui/icons-material';
import Tooltip from '../../components/Tooltip/Tooltip';
import { makeStyles } from '@material-ui/styles';
import Btn from '../../components/Button/Button';

const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto",    margin: "0px 0px 0px 230px",
    backgroundColor:"white" },
    dialogcontainer: { padding: '15px' }
}


const useStyles = makeStyles({
    tooltipTop: {
        "&::before": {
            borderTopColor: "rgba(97, 97, 97, 0.92) !important",
        },
        fontSize: "10px",
    },
    columnEllipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        cursor: "pointer"
    },
})
const StatementofWork = (props: any) => {
    const classes = useStyles()
    const loginData = useLocalStorage.getItem("loginData")
    const [loading, setLoading] = useState(false);
    const [operation, setOperation] = useState('');
    const ApiService = useApiService();
    const deleteTaskRef: any = useRef();
    const [sowData, setSowdata] = useState([]);
    const [open, setOpen] = useState(false);
    const [clickedRow, setClickedRow]: any = useState();
    const navigate = useNavigate()
    const [sowTitleList, setSowTitleList]: any = useState([])
    const [filterCustomerIdArr, setFilterCustomerIdArr]: any = useState([])
    const [sowStatus, setSowStatus] = useState([])
    const [customers, setCustomers]: any = useState([]);
    const [filterProjects, setFilterProjects]: any = useState([]);
    const [tempSOWdata, setTempSOWdata] = useState([]);
    const [allProjects, setAllProjects]: any = useState([]);
    const [projects, setProjects]: any = useState([]);
    const [columnFilterOpen, setColumnFilterOpen] = useState(false);
    const [isDataFiltered, setIsDataFiltered] = useState(false);
    const [value, setvalue] = useState('')
    const [selection, setSelection] = React.useState();
    const [demoStatus, setDemoStatus]: any = useState(["aaa"])
    const [filtersApplied, setFiltersApplied] = useState(false)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const iconDivStyles =
    {
        display: 'flex',
        cursor: "pointer",
    }
    const defaultColumns = [
        { name: 'Project Name', checked: true },
        { name: 'Customer Name', checked: true },
        { name: 'Title', checked: true },
        { name: 'Status', checked: true },
        { name: 'Start Date', checked: true },
        { name: 'Project Type', checked: true },
        { name: 'Total Amount', checked: true },
    ]
    const [filterCheckboxs, setFilterCheckboxs]: any = useState(defaultColumns);



    const handleCardFilterValueChange = (name: any) => {
        // handle checkbox value changes
        setFilterCheckboxs((prevState: any) => {
            console.log(prevState, "====prevsstate")
            // if "All" clicked and checked uncheck everything else check everything.
            const modifiedPrevState = prevState.map((item: any, index: any) => {
                if (item.name === name) {
                    // setValue(`checkboxes.${index}`,{ name:item.name, checked: !item.checked })
                    return { name: item.name, checked: !item.checked };
                } else {
                    // setValue(`checkboxes.${index}`,{ name:item.name, checked: item.checked })
                    return { name: item.name, checked: item.checked };
                }
            });
            console.log(modifiedPrevState, "----modifiedddd")
            // console.log(getValues('checkboxes'),"======================data on apply=======================")
            return modifiedPrevState

        })
    }

    ////////////////get all SOW'S//////////////
    const getSOWs = async () => {
        setLoading(true);
        const reqBody = {}
        const header = {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                setTempSOWdata(res.data);
                setSowdata(res.data);
                // statusTypeListing(res.data)
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            }).finally(() => {

                setLoading(false)

            });
    }
    ///////////////////////////////////////////


    useEffect(() => {
        getDropdownsData();
        getSOWs();
        //    setFilterCheckboxs(localStorage.getItem('checkbox') ?localStorage.getItem('checkbox') : defaultColumns)
    }, [])


    useEffect(() => {
        if (useLocalStorage.getItem('checkbox')) {
            setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('checkbox')))
        }
    }, [])

    const onConfirm = async () => {
        console.log(clickedRow);
        const reqBody =
        {
            sow_id: clickedRow?.sow_id
        }
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.delete(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                deleteTaskRef.current.handleClose();
                showToast('SUCCESS', res.message);

                const newSows = sowData?.filter((value: any) => value.sow_id !== clickedRow?.sow_id)

                setSowdata(newSows);
                // setFilterProjects(newSows);
                // setValue('projects', newSows);
                // setAutocompleteValues({ ...autoCompleteValues, projects: newSows });

            })
    }
    const handleProjectandStatusFilter = (data: any) => {
        if (data) {
            let tempProject: any = []
            let tempStatus: any = []
            let tempSowTitle: any = []
            data.map((d: any, index: any) => {
                tempProject.push({ project_id: d.project_id, project_name: d.project_name, customer_id: d.customer_id, project_type: d.project_type })
                tempSowTitle.push({ title: d.title, customer_id: d.customer_id, sow_id: d.sow_id })
                tempStatus.push({ status: d.status, name: parseInt(d.status) == 1 ? "Open" : parseInt(d.status) == 2 ? "InProgress" : parseInt(d.status) == 3 ? "Onhold" : "Completed" })
            })
            const uniqueStatus = tempStatus.filter((value: any, index: number, self: any) =>
                index === self.findIndex((t: any) => (
                    t.status === value.status && t.name === value.name
                ))
            )
            console.log(tempStatus, "=======sow title======")
            setValue("project", tempProject)
            setFilterProjects(tempProject)
            setSowTitleList(tempSowTitle)
            setSowStatus(uniqueStatus)
        }
    }
    const onDiscard = () => {
        deleteTaskRef.current.handleClose();
    };
    const TableIcons = (row: any) => {
        const handleEdit = (clickedRow: any) => {

            // setLoading(true);

            console.log("------------HANDLE EDIT IS CALLED----------")

            navigate('/add/statement-of-work', { state: { operation: "edit", sowId: clickedRow.sow_id } })
            // setTimeout(() => {
            //     setLoading(false)
            // }, 2500)
        }

        const handleDelete = (clickedRow: any) => {
            console.log("------------HANDLE DELETE IS CALLED----------")
            setClickedRow(clickedRow)
            deleteTaskRef.current.handleClickOpen();
        }

        const handleView = (clickedRow: any) => {
            navigate('/add/statement-of-work', { state: { operation: "view", sowId: clickedRow.sow_id } })
        }


        return (
            <>
                <div style={iconDivStyles}>
                    <div>
                        <img src={ViewIcon} onClick={() => {
                            handleView(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>
                    {loginData?.user_type < 3 && <div>
                        <img src={EditIcon} onClick={() => {
                            handleEdit(row.row)
                        }}
                            className="icon_images"
                        />
                    </div>}
                    <div>
                        {loginData?.user_type < 3 && <img src={DeleteIcon}
                            className="icon_images"
                            onClick={() => {
                                handleDelete(row.row)
                            }} />}
                    </div>
                </div>
            </>
        )
    }

    ////////////////get all SOW'S//////////////


    const validationSchema = Yup.object().shape({
        title:
            Yup.string()
                .trim()
                .required('This field is required'),
        pm_id: Yup.string()
            .trim()
            .required('Please select a Project Manager'),
        lead_id: Yup.string()
            .trim()
            .required('Please select a Department Lead'),
        status: Yup.string()
            .trim()
            .required('SOW status is required'),
        project_detail: Yup
            .string()
            .required('This field is required'),
        payment_terms: Yup
            .string()
            .required('This field is required'),
        customer_detail: Yup
            .string()
            .required('This field is required'),
        amount: Yup.number()
            .typeError('Amount must be a number')
            .required("This field is required")
            .min(0, "Please enter a valid amount"),
        salesperson: Yup.array().of(
            Yup.object().shape({
                sale_person_id: Yup.string().required("This field is required"),
                share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %"),

            })
        ),
        end_date: Yup.date()
            .when("start_date",
                (start_date, Yup) => start_date && Yup.min(start_date, "Enter a valid end date"))
        ,
        department: Yup.array().of(
            Yup.object().shape({
                department_id: Yup.string().required("This field is required"),
                share: Yup.number()
                    .typeError('Share must be a number')
                    .required("This field is required")
                    .min(1, "Please enter a valid share %")
                    .max(100, "Please enter a valid share %")
            })
        ),
        milestone: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required("This field is required"),
                amount: Yup.number()
                    .typeError('Amount must be a number')
                    .required("This field is required")
                    .min(0, "Please enter a valid amount"),
                type: Yup.string().required("This field is required"),
                due_date: Yup.date()
                    .when("invoice_date",
                        (invoice_date, Yup) => invoice_date && Yup.min(invoice_date, "Enter a valid due date"))
            })
        )
    })

    const {
        control,
        formState: { errors, },
        getValues, setValue,
        register,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const getStatus = (status: any) => {
        switch (parseInt(status)) {
            case 1:
                return "Open"
                break;
            case 2:
                return 'Inprogress'
                break;
            case 3:
                return "Onhold"
                break;
            case 4:
                return "Completed"
                break;
            default:
                break;
        }
    } 
    
    const showTooltip = (data: any) => {
        if (data && data.length > 11) {
            return (
                <Tooltip
                    content={data}
                    direction={"top"}
                    style={{
                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                        // marginTop: "-40px",
                        // marginLeft: "65px"
                    }}
                    wrapperStyle={{
                        width: "115px",
                    }}
                >
                    <span className={classes.columnEllipsis}>{data}</span>
                </Tooltip>
            );
        } else {
            return <span>{data}</span>;
        }
    };

    let columns: any = [
        {
            name: "Project Name",
            selector: "project_name",
            wrap: true,
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[0]?.checked == false : false,
            minWidth: "150px",
            cell: (row: any) => {
                return <Link underline='none' style={{ cursor: "pointer" }} onClick={() => navigate('/add/statement-of-work', { state: { operation: "view", sowId: row.sow_id } })
                }> {showTooltip(row['project_name'])}</Link>
            }
        },
        {
            name: "Customer Name",
            selector: "customer_name",
            wrap: true,
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[1]?.checked == false : false,

            minWidth: "150px",
            cell:(row:any)=>{
                return showTooltip(row['customer_name'])
            }
        },

        {
            name: "Title",
            selector: "title",
            wrap: true,
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[2]?.checked == false : false,

            minWidth: "175px",
            cell:(row:any)=>{
                return showTooltip(row['title'])
            }
        },
        {
            name: "Status",
            selector: "status",
            wrap: true,
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[3]?.checked == false : false,

            minWidth: "125px",
            cell: (row: any) => getStatus(row['status'])
        },
        {
            name: "Start Date",
            selector: "start_date",
            wrap: true,
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[4]?.checked == false : false,

            minWidth: "125px",
            cell: (row: any) => moment(row['date']).format('ll')
        },
        {
            name: "Project Type",
            selector: "project_type",
            wrap: true,
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[5]?.checked == false : false,

            minWidth: "150px",
        },
        {
            name: "Total Amount",
            selector: "amount",
            omit: useLocalStorage.getItem("checkbox") ?
                JSON.parse(useLocalStorage.getItem('checkbox'))?.[6]?.checked == false : false,

            wrap: true,
            minWidth: "150px",
            cell: (row: any) => {
                return getCurrencySymbol(row['customer_billing_currency']) + ' ' + (row['amount'].toLocaleString('en-IN'))
            }
        },
        {
            name: "Actions",
            allowOverflow: true,
            button: true,
            selector: "is_active",
            cell: (row: any) => <div ><TableIcons row={row} /></div>
        },
    ];

    const handleCustomerFilter = () => {
        console.log(getValues("customers"), "-----customer----")

        if (getValues("customers").length) {
            let tempCustomerIdArr: any = []
            getValues("customers").map((customer: any) => {
                tempCustomerIdArr.push(customer.customer_id)
            })
            setValue("customerIds" , tempCustomerIdArr)
            setFilterCustomerIdArr(tempCustomerIdArr)
            getProjectsAndStatus(tempCustomerIdArr)
        }else{
            setAutocompleteValues(({
                projects: [],
                sowTitle: [],
                statusType: [],
            }))
        }
    }
    const getSowFromProjects = () => {
        if (getValues("projects")) {
            let tempProjectIdArr: any = []
            getValues("projects").map((project: any) => {
                tempProjectIdArr.push(project.project_id)
            })
            setValue("projectIds",tempProjectIdArr)
        }
    }
    const getParticularSOW = async () => {
        let reqBody: any = {}
console.log(getValues("projectIds"))
console.log(getValues("customerIds"))
console.log(getValues("titleArr"))
console.log(getValues("statusArr"))
if(getValues("projectIds")?.length){
reqBody.project_id = getValues("projectIds")
} 
if(getValues("customerIds")?.length){
    reqBody.customer_id = getValues("customerIds")
    }
    if(getValues("titleArr")?.length){
        reqBody.title =getValues("titleArr")
    }
    if(getValues("statusArr")?.length){
        reqBody.status = getValues("statusArr")
    }
        
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        setLoading(true)
        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {
                setSowdata(res.data)
                setFiltersApplied(true)
            })
            .catch((err: any) => {
                showToast("ERROR", err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const [autoCompleteValues, setAutocompleteValues]: any = useState
        ({
            customers: [],
            projects: [],
            sowTitle: [],
            statusType: [],
        })
    const getSOWfromTitle = () => {
        if (getValues("sow_title")) {
            let tempSOWtitle: any = []
            getValues("sow_title").map((title: any) => {
                tempSOWtitle.push(title.title)
            })
            setValue("titleArr",tempSOWtitle)
            // getParticularSOW(tempSOWtitle, "title")
        }
    }
    const getSOWFromStatus = () => {
        if (getValues("sow_status")) {
            let tempSOWStatus: any = []
            getValues("sow_status").map((status: any) => {
                tempSOWStatus.push(status.status)
            })
            setValue("statusArr",tempSOWStatus)
            // getParticularSOW(tempSOWStatus, "status")
        }
    }

    const getProjectsAndStatus = async (customerId: any) => {
        const reqBody = {
            customer_id: customerId
        };
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData = {
            reqBody: reqBody,
            headers: header
        };
        // setLoading(true)
        await ApiService.get(Statementofworkendpoints.sow(reqData))
            .then((res: any) => {

                // console.log(res.data,"------responseee")
                // setSowdata(res.data)
                handleProjectandStatusFilter(res.data)
            })
            .catch((err: any) => {
                showToast("ERROR", err.message)
            })
            .finally(() => {
                // setLoading(false)
            })
    }

    const getDropdownsData = async () => {

        let tempProjects: any = [];
        let tempCustomers: any = [];

        const reqBody = {}
        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };

        await ApiService.get(Statementofworkendpoints.sow_project(reqData))
            .then((res: any) => {
                tempProjects = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })

        await ApiService.get(Statementofworkendpoints.sow_customer(reqData))
            .then((res: any) => {
                tempCustomers = res.data;
            }).catch((err: any) => {
                showToast('ERROR', err.message);
            })
        ////////////////get all the sow information////////
        // getSOWs();

        console.log("----------DROPDOWN DATA----------")

        // console.log(tempProjects);
        // console.log(tempCustomers);
        setAllProjects(tempProjects);
        setProjects(tempProjects);
        setCustomers(tempCustomers);
    }

    const handleAddSow = () => {

        navigate('/add/statement-of-work', { state: { operation: "add" } })
    }

    const drawerClose = () => () => {
        setColumnFilterOpen(false);
        setFilterCheckboxs(defaultColumns)
        useLocalStorage.removeItem("checkbox")
    };
    console.log(filterCheckboxs, "==========================mapppping")


    const toggleDrawer =
        (anchor: any, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };




    const list = (anchor: any) => (
        <Box
            sx={{ width: 250, margin: "60px 0px 0px 20px" }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                  <div style={{display:"flex" , justifyContent:"space-between" , margin:"10px 17px 10px 0px" , alignItems:"center"}}>
            
                <h2>Column Filters</h2>
                <Close style={{cursor:"pointer"}} onClick={()=>{
if (useLocalStorage.getItem('checkbox')) {
    setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('checkbox')))
    setColumnFilterOpen(false)
}
else {
    setFilterCheckboxs(defaultColumns)
    setColumnFilterOpen(false)
}
            }}/>
            </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    {filterCheckboxs?.map((item: any, index: number) => (
                        <div>
                            <FormControlLabel
                                key={item.name}
                                label={item.name}
                                control={
                                    <Checkbox
                                        {...register(`checkboxes.${index}`)}
                                        disableRipple={true}
                                        disableTouchRipple={true}
                                        style={{
                                            padding: "10px 10px"
                                        }}
                                        checked={item.checked}
                                        onChange={() => handleCardFilterValueChange(item.name)}
                                    />
                                }
                            />
                        </div>
                    ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "5px", margin: "20px 0px 0px 0px" }}>

                    <Btn
                        variant='outlined'
                        type="button"
                        btnText='Reset'
                        onClick={drawerClose()}
                    />
                    <Btn
                        variant='contained'
                        type="button"
                        btnText='Apply'
                        onClick={handleFilteration}
                    />
                </div>


            </List>
        </Box>
    );

    console.log(getValues('checkboxes'), "======================data on apply=======================")
    const handleFilteration = () => {
        // console.log(getValues('checkboxes'),"======================data on apply=======================")
        useLocalStorage.setItem('checkbox', JSON.stringify(filterCheckboxs))
        console.log(filterCheckboxs, "===========this is what we want=====")
        setColumnFilterOpen(false)

    }
    return (
        <React.Fragment>
            <Loader loading={loading}>
                <div style={{ maxHeight: "100vh" }}>


                    <Container maxWidth={false} style={{ ...styles.container, display: !open ? 'block' : 'none' }} className='Users'>
                        <Accordion style={{ margin: "10px 0px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
          <Typography style={{display:"flex" , alignItems:"center", gap:"10px"}}>Filters <span className='dot' style={filtersApplied? {backgroundColor:"green"} : {backgroundColor:"red"}}></span> </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                                    <Grid item lg={6} md={6}>
                                        <Controller
                                            control={control}
                                            name={`customers`}
                                            render={({ field: any }) => (
                                                <Autocomplete
                                                    multiple={true}
                                                    menuItems={customers}
                                                    disabled={operation === 'view'}
                                                    valueKey='customer_id'
                                                    showNameKey='name'
                                                    getOptionSelected={(option: any, value: any) => option.customer_id === value.customer_id}
                                                    value={autoCompleteValues.customers}
                                                    label='Select Customer'
                                                    placeholder='add customer'
                                                    onChange={(e: any, value: any) => {
                                                        setValue("customers", value);
                                                        handleCustomerFilter()
                                                        setAutocompleteValues({ ...autoCompleteValues, customers: value })
                                                    }}
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item lg={6} md={6}>
                                        <Controller
                                            control={control}
                                            name={`projects`}
                                            render={({ field: any }) => (
                                                <Autocomplete
                                                    multiple={true}
                                                    menuItems={filterProjects}
                                                    disabled={!autoCompleteValues?.customers?.length}
                                                    valueKey='project_id'
                                                    showNameKey='project_name'
                                                    getOptionSelected={(option: any, value: any) => option.project_id === value.project_id}
                                                    value={autoCompleteValues.projects}
                                                    label='Select Project'
                                                    placeholder='add project'
                                                    onChange={(e: any, value: any) => {
                                                        setValue('projects', value);
                                                        getSowFromProjects()
                                                        setAutocompleteValues({ ...autoCompleteValues, projects: value })
                                                        // setSowdata(value)
                                                    }}
                                                />
                                            )}
                                        />

                                    </Grid>

                                    <Grid item lg={6} md={6}>

                                        <Controller
                                            control={control}
                                            name={`status_title`}
                                            render={({ field: any }) => (
                                                <Autocomplete
                                                    multiple={true}
                                                    menuItems={sowTitleList}
                                                    disabled={!autoCompleteValues?.customers?.length}
                                                    valueKey='title'
                                                    showNameKey='title'
                                                    getOptionSelected={(option: any, value: any) => option.sow_id === value.sow_id}
                                                    value={autoCompleteValues.sowTitle}
                                                    label='Select SOW Title'
                                                    placeholder='add title'
                                                    onChange={(e: any, value: any) => {
                                                        setValue('sow_title', value);
                                                        getSOWfromTitle()
                                                        setAutocompleteValues({ ...autoCompleteValues, sowTitle: value })
                                                        // setSowdata(value)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={6} lg={6} >
                                        <Controller
                                            control={control}
                                            name={`status`}
                                            render={({ field: any }) => (
                                                <Autocomplete
                                                    multiple={true}
                                                    menuItems={sowStatus}
                                                    disabled={!autoCompleteValues?.customers?.length}
                                                    valueKey='status'
                                                    showNameKey='name'
                                                    getOptionSelected={(option: any, value: any) => option.status === value.status}
                                                    value={autoCompleteValues.statusType}
                                                    label='Select Status'
                                                    placeholder='add status'
                                                    onChange={(e: any, value: any) => {
                                                        setValue('sow_status', value);
                                                        getSOWFromStatus()
                                                        // getSowFromProjects()
                                                        setAutocompleteValues({ ...autoCompleteValues, statusType: value })
                                                        // setSowdata(value)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={12} style={{ marginTop:"10px",display:"flex" , justifyContent:"flex-end" , gap:"10px" }}>
                            <Btn 
                            variant='outlined'
                            type='button'
                                style={{ borderRadius: "25px"}}
                                onClick={() => {
                                   setAutocompleteValues(({
                                    customers: [],
                                    projects: [],
                                    sowTitle: [],
                                    statusType: [],
                                }))
                                setFiltersApplied(false)
                                getSOWs()
                                }}
                                btnText={'Reset'}
                            />

                            <Btn 
                            type='button'
                            variant='contained'
                                style={{ borderRadius: "20px",height:"fit-content" }}
                                onClick={() => {
                                    getParticularSOW()
                                }}
btnText={'Apply'}
                            />
                        </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>



                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <Grid item lg={6} md={6} className='display__style__1'>
                                {/* ////LEAD CAN'T ADD SOW///// */}
                                {loginData?.user_type < 3 && <Btn variant="contained" type='button' onClick={() =>
                                    handleAddSow()
                                } btnText={"Add New SOW"}></Btn>}
                            </Grid>
                            <Grid item lg={1} md={1} className='display__style__1'>
                                <Tooltip
                                    content={'Column Filters'}
                                    direction={"top"}
                                    style={{
                                        backgroundColor: "rgba(97, 97, 97, 0.92)",
                                        marginTop: "-10px",
                                        marginLeft:"-30px",
                                    }}
                                    wrapperStyle={{
                                        width: "-webkit-fill-available",
                                    }}
                                    hideOnClick={true}
                                    className={classes.tooltipTop}
                                >
                                    <div className="jobList__notification">
                                        <div
                                            className="filterButton"
                                            style={{ transform: "none" , display:"flex" , justifyContent:"center", cursor:"pointer" }}
                                            onClick={() => setColumnFilterOpen(!columnFilterOpen)}
                                        >
                                            <FilterAlt style={{color:"rgba(0,126,191,1)"}} />
                                        </div>
                                        <Drawer
                                            anchor={"right"}
                                            open={columnFilterOpen}
                                            onClose={() => {
                                                if (useLocalStorage.getItem('checkbox')) {
                                                    setFilterCheckboxs(JSON.parse(useLocalStorage.getItem('checkbox')))
                                                    setColumnFilterOpen(false)
                                                }
                                                else {
                                                    setFilterCheckboxs(defaultColumns)
                                                    setColumnFilterOpen(false)
                                                }
                                            }
                                            }
                                        >
                                            {list('right')}
                                            {/* {columnListFilter()} */}
                                        </Drawer>
                                    </div>
                                </Tooltip>
                            </Grid>


                            <Grid item lg={12} md={12}>
                                {/* ------------------LISTING OF SOW------------ */}
                                <Datatable
                                    columns={columns}
                                    data={sowData}
                                    noDataComponent={!loading ? sowData?.length ? " " : " No records to display " : " "}

                                />
                            </Grid>
                        </Grid>
                    </Container>
                    <Suspense fallback={""}>
                        <AlertDialog
                            ref={deleteTaskRef}
                            description={"Are you sure you want to delete this Statement of work?"}
                            title={"Delete SOW"}
                            confirm={"Confirm"}
                            discard={"Discard"}
                            onConfirm={onConfirm}
                            onDiscard={onDiscard}
                        ></AlertDialog>
                    </Suspense>
                </div>
            </Loader>
        </React.Fragment>
    )


}

export default StatementofWork;