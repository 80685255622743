import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@material-ui/styles';
import { borderRadius } from '@mui/system';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles({
  paper: {
    // borderRadius:"25px !important",
  },
  inputRoot:{
borderRadius:"25px !important",

// border:"1px solid grey"
  }
});
export default function CheckboxesTags(props: any) {
const classes = useStyles()
  const { menuItems, showNameKey, showValueKey, onChange, label, value, limitTags, placeholder, disabled } = props;


  return (
    <Autocomplete
      multiple={props.multiple || false}
      onInputChange={props.onInputChange}
      disabled={disabled}
      PaperComponent={props.PaperComponent}
      disableClearable={true}
      onChange={onChange}
      renderOption={props.renderOption}
      // renderInput={props.renderInput}
      id="checkboxes-tags-demo"
      options={menuItems}
      renderTags={props.renderTags}
      classes={{paper : classes.paper , inputRoot:classes.inputRoot }}
      disableCloseOnSelect
      isOptionEqualToValue={props.getOptionSelected}
      value={value}
      getOptionLabel={(option: any) => option[showNameKey]}
      // limitTags={limitTags}
      filterSelectedOptions={true}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder}  InputLabelProps={{style:{overflow:"ellipsis",paddingRight:"15px"}}} />
      )}
    />
  );
}