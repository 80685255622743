const ProjectsEndpoints = {
    project(reqData: any) {
      return {
        url: "sow/project",
        data: reqData["reqBody"],
        headers: reqData["headers"],
      };
    },
    customer(reqData: any) {
        return {
          url: "sow/customer",
          data: reqData["reqBody"],
          headers: reqData["headers"],
        };
      }
  };
  
  export default ProjectsEndpoints;