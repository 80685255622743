import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import React, { useImperativeHandle, useState, forwardRef } from 'react';
import Input from '../../../components/Input/Input';
//---------------------------------------------------
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DropDownComponent from '../../../components/Select/Select';
import useApiService from '../../../services/api.service';
import useLocalStorage from '../../../hooks/localStorage';
import ProjectsEndpoints from '../../Projects/Projects.endpoints';
import Loader from '../../../components/Loader/Loader';
import { showToast } from '../../../components/Toast/Toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Errormessage from '../../../components/Errormessage/Errormessage';
import Btn from '../../../components/Button/Button';
const styles =
{
    container: { borderRadius: '20px', minHeight: '80vh', padding: "3vh", width: "auto", margin: "80px 40px 40px 260px" },
    dialogcontainer: { padding: '15px', width: '600px' },
}

const dummyPaymentData =
    [
        { name: 'Monthly Retainer' },
        { name: 'Adhoc Basis' },
        { name: 'Fixed Cost' },
    ]

const Projectdialog: React.FC<any> = forwardRef((props: any, ref: any) => {

    const { customerData , customerName } = props;
    console.log(customerName, "=======customerdata")
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const ApiService = useApiService();
    const loginData = useLocalStorage.getItem("loginData");

    useImperativeHandle(ref, () =>
    ({
        handleClickProjectDialogOpen: () => {
            setOpen(true);
        },
        handleClickProjectDialogClose: () => {
            setOpen(false);
        }
    }));


    // ---------------validation schema-----------------
    const validationSchema = Yup.object().shape({
        project_name: Yup.string()
            .trim()
            .required('This field is required'),
        project_type:
            Yup.string().required('This field is required'),
        // customer_id:
        //     Yup.string().required('This field is required')

    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        getValues, setValue,
        reset
    } = useForm
            ({
                resolver: yupResolver(validationSchema)
            });


    const submitProject = async (data: any) => {
        setLoading(true);

        console.log("-----------FORM SUBMITTED----------")
        console.log(data);

        const reqBody =
        {
            project_name: data?.project_name,
            project_type: data?.project_type,
            customer_id: customerName? customerName : ""
        }

        const header =
        {
            access_token: loginData?.access_token
        }
        const reqData =
        {
            reqBody: reqBody,
            headers: header
        };
        await ApiService.post(ProjectsEndpoints.project(reqData))
            .then((res: any) => {
                showToast('SUCCESS', res.message);
                setOpen(false);
                props.updateProjects(({ project_id: res.data.project_id, project_name: data.project_name, project_type: data.project_type, customer_id: data?.customer_id }));
                resetFields();
            }).catch((err: any) => {
                showToast('ERROR', err.message);
                /////////set loading to false/////////
            })
        setLoading(false);
    }


    const resetFields = () => {
        reset
            ({
                project_name: '',
                project_type: '',
                customer_id: '',
            })
    }

    return <React.Fragment>
        <Loader loading={loading}>
            <Dialog
                open={open}
                onClose={() => {

                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={styles.dialogcontainer}>
                    <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "0px" }}>
                        <p className="dialog_title">
                            SOW Project
                        </p>
                    </DialogTitle>

                    <form >
                        <DialogContent >
                            <DialogContentText id="alert-dialog-description">
                                <Grid container spacing={1}>
                                <Grid item xs={12} lg={12} md={12} style={{marginTop:'8px'}}>
                                        <Controller
                                            control={control}
                                            name="customer_id"
                                            render={({ field: any }) => (
                                                <DropDownComponent
                                                disabled={true}
                                                    label='Customer*'
                                                    menuItems={customerData}
                                                    valueKey='customer_id'
                                                    showNameKey='name'
                                                    // onChange={(e: any) => {
                                                    //     setValue('customer_id', e.target.value)
                                                    // }}
                                                    // error={errors.customer_id ? true : false}
                                                    value={customerName?customerName : ""}
                                                />
                                            )}
                                        />
                                        {/* <Errormessage errormessage={errors.customer_id ? errors.customer_id.message : ''} /> */}
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Input
                                            // required={true}
                                            type="text"
                                            label="Project Name*"
                                            register={register('project_name')}
                                            autoFocus={true}
                                            error={errors.project_name ? true : false}
                                        />
                                        <Errormessage errormessage={errors.project_name ? errors.project_name.message : ''} />
                                    </Grid>

                                    <Grid item xs={12} lg={12} md={12}>
                                        <Controller
                                            control={control}
                                            name="project_type"
                                            render={({ field: any }) => (
                                                <DropDownComponent
                                                    label='Project Type*'
                                                    menuItems={dummyPaymentData}
                                                    valueKey='name'
                                                    showNameKey='name'
                                                    onChange={(e: any) => {
                                                        setValue('project_type', e.target.value)
                                                    }}
                                                    error={errors.project_type ? true : false}
                                                    value={getValues('project_type')}
                                                />
                                            )}
                                        />
                                        <Errormessage errormessage={errors.project_type ? errors.project_type.message : ''} />
                                    </Grid>

                                 

                                </Grid>
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <Btn
                                variant="outlined"
                                onClick={() => {
                                    resetFields();
                                    setOpen(false);
                                } } 
                                btnText={'Cancel'} type={'button'} />
                            <Btn type="button" variant="contained" onClick={handleSubmit(submitProject)} btnText={'Save'}/>
                                
                        </DialogActions>
                    </form>
                </div>

            </Dialog>
        </Loader>
    </React.Fragment>
})

export default Projectdialog;