const ForgotpasswordEndpoints = {
    forgotPassword(reqData: any) {
      return {
        url: "auth/user/forgotPassword",
        data: reqData["reqBody"],
        headers: reqData["headers"],
      };
    }
  };
  
  export default ForgotpasswordEndpoints;