const LoginEndpoints = {
  // -----------METHOD IS POST---------
    login(reqData: any) {
      return {
        url: "auth/user/login",
        data: reqData["reqBody"],
        headers: reqData["headers"],
      };
    }
  };
  export default LoginEndpoints;